import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import classNames from 'classnames';
import PmMtInvoiceTab from './pm-mt-view-invoice-tab';
import PmMtSalesSowTab from '../pm-mt-sales-components/pm-mt-sales-issue-sow-tab';
import UserInfoController from '../../../api/controller/userinfo-controller';
import ProjectSowController from '../../../api/controller/projectsow-controller';
import SowInvoiceController from '../../../api/controller/sowinvoice-controller';
import conf_prop from '../../../properties/properties';
import { useNavigate } from 'react-router-dom';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';

function PmMtManagePartnerTab({project, userInfo}) {

    const navigate = useNavigate();
    const [newPartnerModal, setNewPartnerModal] = useState(false);
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [isLoaded, set_isLoaded] = useState(false);
    const [allPartners, set_allPartners] = useState([]);
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const [partner, set_partner] = useState({});
    const [pmProjectSowList, set_pMProjectSowList] = useState([]);
    const [mtProjectSowList, set_mTProjectSowList] = useState([]);
    const [mtViewSowInvoices, set_mTViewSowInvoices] = useState([]);
    const [pmViewSowInvoices, set_pmViewSowInvoices] = useState([]);
    const [btnDisabled, set_btnDisabled] = useState(true);
    const [projectSow, set_projectSow] = useState({
        'project': {
            'projectId': project.projectId,
        }, 'projectAdminPartner': {'userInfo': {'userId': partner.userId}},
    });

    const initiatedProjectSowList = project.projectSows.filter(x => x.sowStatus !== 'Issued');

    function handleChangeTab(activeTabInput) {
        setActiveTab(activeTabInput);
    }


    function toggleNewPartnerModal() {
        setNewPartnerModal((newPartnerModalPrev) => {
            return !newPartnerModalPrev;
        });
        set_btnDisabled(true);
    }

    function onPartnersSelect(partner) {
        set_partner(partner);
        set_projectSow({...projectSow, 'projectAdminPartner': {'userInfo': {'userId': partner.userId}}});
        set_btnDisabled(false);
    }

    async function findPartnerList() {
        await UserInfoController.findAll().then(userInfoList => {
            const userInfoPartners = userInfoList.filter(userInfo => userInfo.role.internalName === 'PARTNER');
            const partnerList = userInfoPartners.map((userInfo) => {
                userInfo.label = userInfo.name;
                userInfo.value = userInfo.userId;
                return userInfo;
            });
            set_allPartners(partnerList);
        }).catch(error => {
            console.error(error);
            set_isLoaded(true);

        });
    }

    function insertProjectSOW() {
        set_isUpLoaded(true);
        ProjectSowController.insertProjectSow(projectSow).then(result => {
            toastify('success', t('pm.project.sow.inserted.sucessfully'), t('pm.project.sow.inserted.sucessfully'));
            set_isUpLoaded(false);
            toggleNewPartnerModal();
            navigate(conf_prop.get('route_path') + '/program-manager/view-sow/' + result.psowId_ENCRYPTED, {replace: true});
        }).catch(error => {
            console.error(error);
            set_isUpLoaded(false);
        });
    }


    async function findSowByProject() {
        set_isLoaded(false);
        await ProjectSowController.findByProject(project.projectId_ENCRYPTED).then(projectSows => {

            projectSows.sort((a, b) => {
                if (a.sowStatus === 'Issued' && b.sowStatus !== 'Issued') {
                    return -1; // "Issued" status comes before any other status
                } else if (a.sowStatus !== 'Issued' && b.sowStatus === 'Issued') {
                    return 1; // Any other status comes after "Issued" status
                } else {
                    // If both have the same status or both are not "Issued", sort by id in descending order
                    return a.psowId - b.psowId;
                }
            });

            const pMProjectSows = projectSows.filter(x => {
                x.psowId = 'SOW' + x.psowId;
                return x;
            });

            set_pMProjectSowList(pMProjectSows);

        }).catch(error => {
            console.error(error);
            set_isLoaded(true);
        });
    }

    async function findSowInvoiceByProject() {
        set_isLoaded(false);
        await SowInvoiceController.findByProject(project.projectId_ENCRYPTED).then(sowInvoices => {

            sowInvoices.sort((a, b) => {
                if (a.invoiceStatus === 'PInitiated' && b.invoiceStatus !== 'PInitiated') {
                    return -1; // "PInitiated" status comes before any other status
                } else if (a.invoiceStatus !== 'PInitiated' && b.invoiceStatus === 'PInitiated') {
                    return 1; // Any other status comes after "PInitiated" status
                } else {
                    // If both have the same status or both are not "PInitiated", sort by id in descending order
                    return a.sowiId - b.sowiId;
                }
            });

            const filteredPmSalesSowInvoices = sowInvoices.filter(sowInvoice => {
                sowInvoice.sowiId =  sowInvoice.sowiId;
                sowInvoice.psowId = 'SOW' + sowInvoice.projectSow.psowId;
                return sowInvoice.invoiceStatus !== 'PIssued';
            });

            // const afterOrderBySowInvoiceStatus = _.orderBy(filteredSowInvoices, [(sowInvoice) => (sowInvoice.invoiceStatus === 'PInitiated' ? 0 : 1)], 'genDate', ['asc'])
            // const finalPmProjectSowInvoiceListAfterOrderByGenDate = _.orderBy(afterOrderBySowInvoiceStatus, 'genDate', ['desc'])
            set_pmViewSowInvoices(filteredPmSalesSowInvoices);

            /*======================================MT FILTERED DATA=============================================================*/
            sowInvoices.sort((a, b) => {
                if (a.invoiceStatus === 'PMApproved' && b.invoiceStatus !== 'PMApproved') {
                    return -1; // "PMApproved" status comes before any other status
                } else if (a.invoiceStatus !== 'PMApproved' && b.invoiceStatus === 'PMApproved') {
                    return 1; // Any other status comes after "PMApproved" status
                } else {
                    // If both have the same status or both are not "PMApproved", sort by id in descending order
                    return a.sowiId - b.sowiId;
                }
            });

            const filteredMtSowInvoices = sowInvoices.filter(sowInvoice => {
                sowInvoice.sowiId = sowInvoice.sowiId;
                sowInvoice.psowId = 'SOW' + sowInvoice.projectSow.psowId;
                return sowInvoice.invoiceStatus === 'PMApproved' || sowInvoice.invoiceStatus === 'MTApproved' || sowInvoice.invoiceStatus === 'MTRejected' || sowInvoice.invoiceStatus === 'FinanceApproved';
            });

            // const afterOrderBySowInvoiceStatus = _.orderBy(filteredSowInvoices, [(sowInvoice) => (sowInvoice.invoiceStatus === 'PMApproved' ? 0 : 1)], 'genDate', ['asc'])
            // const finalMtProjectSowInvoiceListAfterOrderByGenDate = _.orderBy(afterOrderBySowInvoiceStatus, 'genDate', ['desc'])
            set_mTViewSowInvoices(filteredMtSowInvoices);

        }).catch(error => {
            console.error(error);
            set_isLoaded(true);
        });
    }


    /*    async function findSowByProjectAndUserInfoMt() {
            set_isLoaded(false);
            await ProjectSowController.findByProjectAndUserInfoMt(project.projectId_ENCRYPTED, userInfo.userId_ENCRYPTED).then(projectSows => {

                projectSows.sort((a, b) => {
                    if (a.invoiceStatus === 'Initiated' && b.invoiceStatus !== 'Initiated') {
                        return -1; // "Initiated" status comes before any other status
                    } else if (a.invoiceStatus !== 'Initiated' && b.invoiceStatus === 'Initiated') {
                        return 1; // Any other status comes after "Initiated" status
                    } else {
                        // If both have the same status or both are not "Initiated", sort by id in descending order
                        return a.psowId - b.psowId;
                    }
                });


                const projectSowsList = projectSows.filter(projectSow => {
                    projectSow.psowId = 'SOW' + projectSow.psowId;
                    return projectSow.sowStatus !== 'Issued';
                });

                // const afterOrderBySowStatus = _.orderBy(projectSowsList, [(projectSow) => (projectSow.sowStatus === 'Initiated' ? 0 : 1)], 'genDate', ['asc'])
                // const finalMtProjectSowListAfterOrderByGenDate = _.orderBy(afterOrderBySowStatus, 'genDate', ['desc'])
                set_mTProjectSowList(projectSowsList);

            }).catch(error => {
                console.error(error);
                set_isLoaded(true);
            });
        }*/

    /*async function findSowInvoiceByProjectAndUserInfoMt() {
        set_isLoaded(false);
        await SowInvoiceController.findByProjectAndUserInfoMt(project.projectId_ENCRYPTED, userInfo.userId_ENCRYPTED).then(sowInvoices => {

            sowInvoices.sort((a, b) => {
                if (a.invoiceStatus === 'PMApproved' && b.invoiceStatus !== 'PMApproved') {
                    return -1; // "PMApproved" status comes before any other status
                } else if (a.invoiceStatus !== 'PMApproved' && b.invoiceStatus === 'PMApproved') {
                    return 1; // Any other status comes after "PMApproved" status
                } else {
                    // If both have the same status or both are not "PMApproved", sort by id in descending order
                    return a.sowiId - b.sowiId;
                }
            });

            const filteredSowInvoices = sowInvoices.filter(sowInvoice => {
                sowInvoice.sowiId = 'INV' + sowInvoice.sowiId;
                sowInvoice.psowId = 'SOW' + sowInvoice.projectSow.psowId;
                return sowInvoice.invoiceStatus === 'PMApproved' || sowInvoice.invoiceStatus === 'MTApproved' || sowInvoice.invoiceStatus === 'MTRejected' || sowInvoice.invoiceStatus === 'FinanceApproved';
            });

            // const afterOrderBySowInvoiceStatus = _.orderBy(filteredSowInvoices, [(sowInvoice) => (sowInvoice.invoiceStatus === 'PMApproved' ? 0 : 1)], 'genDate', ['asc'])
            // const finalMtProjectSowInvoiceListAfterOrderByGenDate = _.orderBy(afterOrderBySowInvoiceStatus, 'genDate', ['desc'])
            set_mTViewSowInvoices(filteredSowInvoices);

        }).catch(error => {
            console.error(error);
            set_isLoaded(true);
        });
    }*/


    useEffect(() => {
        set_isLoaded(false);

        async function fetchData() {
            await findSowInvoiceByProject();
            await findPartnerList();

            set_isLoaded(true);
        }

        fetchData();

    }, []);


    return (<div>

        {userInfo.role.internalName === 'PM' ? (<div className="text-end">
            <Button className="next_btn "
                    onClick={toggleNewPartnerModal}
                    disabled={project.projectStatus === 'N'}
            >
                {t('program.manager.manage.partner.btn.issue.sow')}</Button>
        </div>) : (<span></span>)}


        <div className="next_tabs next_client_tabs modal_containt_e2e">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        // href={"#1"}
                        className={classNames({active: activeTab === 1})}
                        onClick={() => {
                            handleChangeTab(1);
                        }}
                    >
                        {t('program.manager.manage.partner.nav.sow')}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        // href={"#2"}
                        className={classNames({active: activeTab === 2})}
                        onClick={() => {
                            handleChangeTab(2);
                        }}
                    >
                        {t('program.manager.manage.partner.nav.item.invoice')}
                    </NavLink>
                </NavItem>
            </Nav>
        </div>

        <TabContent activeTab={activeTab}>

            {/*  {userInfo.role.internalName === "PM" ? (<TabPane tabId={1}>

                <PmMtSowTab project={project} projectSowList={pmProjectSowList} userInfo={userInfo}/>

            </TabPane>) : (<TabPane tabId={1}>

                <PmMtSowTab project={project} projectSowList={mtProjectSowList} userInfo={userInfo}/>

            </TabPane>)}*/}
            <TabPane tabId={1}>
            <PmMtSalesSowTab project={project} userInfo={userInfo} initiatedProjectSowList={initiatedProjectSowList}/>
            </TabPane>
            {userInfo.role.internalName === 'PM' || userInfo.role.internalName === 'SALES' ? (<TabPane tabId={2}>

                <PmMtInvoiceTab project={project} sowInvoices={pmViewSowInvoices}/>

            </TabPane>) : (<TabPane tabId={2}>

                <PmMtInvoiceTab project={project} sowInvoices={mtViewSowInvoices}/>

            </TabPane>)}

        </TabContent>


        {/* ======================================   Raise New Sow modal ===================================== */}

        <Modal
            isOpen={newPartnerModal}
            toggle={toggleNewPartnerModal}
            //className={this.props.className}
            backdrop="static"
        >
            <ModalHeader
                toggle={toggleNewPartnerModal}>Issue SOW</ModalHeader>
            <ModalBody>

                <FormGroup>
                    <Row>
                        <Col md={4}><label
                            className="mt-1"><label>{t('program.manager.manage.partner.modal.select.partner')}</label></label></Col>
                        <Col md={8}>
                            <Select
                                options={allPartners}
                                onChange={onPartnersSelect}
                                classNamePrefix="next_select"
                                placeholder="Select from drop down.."/>
                        </Col>
                    </Row>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn"
                        onClick={toggleNewPartnerModal}
                >
                    Cancel
                </Button>

                <ButtonWithLoader className="next_btn"
                                  onClick={insertProjectSOW}
                                  loading={isUpLoaded}
                                  text={'Issue'}
                                  disabled={btnDisabled}
                >
                </ButtonWithLoader>


            </ModalFooter>
        </Modal>
    </div>);


}

export default PmMtManagePartnerTab;