import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTableView from '../../../components/data-table/data-table-view';
import _ from 'lodash';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import update from 'immutability-helper';
import ServiceTypeController from '../../../api/controller/servicetype-controller';
import ProjectServiceCostController from '../../../api/controller/projectservicecost-controller';
import Select from 'react-select';
import { costAnalysisSchema } from '../../../validation/cost-analysis-validation';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';

function PmMtProjectInvoice({
                                project,
                                findProjectById,
                                userInfo,
                                updateServiceModalToggle,
                                updateServiceModal,
                                newServiceModalToggle,
                                newServiceModal,
                                salesProjectAdmin,
                            }) {

    const [serviceTypeList, set_serviceTypeList] = useState([]);
    const {t} = useTranslation();
    const [projectServiceCostList, set_projectServiceList] = useState([]);
    const [isAddBtnLoading, set_isAddBtnLoading] = useState(false);
    const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
    const [projectServiceCost, set_projectServiceCost] = useState({
        unitCost: '',
        unitCount: '',
        totalCost: '',
        comments: '',
        // project: {projectId: project.projectId},
        serviceType: {stId: ''},
        // projectAdmin: project.projectAdmin
    });
    const [updateProjectServiceCost, set_updateProjectServiceCost] = useState({
        psCostId: '', unitCost: '', unitCount: '', totalCost: '', comments: '', project: {
            projectId: project.projectId
        }, serviceType: {
            stId: ''
        }, projectAdmin: project.projectAdmin

    });
    const schema = useMemo(() => costAnalysisSchema, []);
    const [errorMessage, set_errorMessage] = useState(null);
    const [unitCostErrorMessage, set_unitCostErrorMessage] = useState(null);

    function disabilecostAnalysisBtn() {
        return !schema.isValidSync(projectServiceCost);
    }

    function closeServiceModalToggle() {
        newServiceModalToggle();
        set_projectServiceCost({
            unitCost: '',
            unitCount: '',
            totalCost: '',
            comments: '',
            // project: {projectId: project.projectId},
            serviceType: {stId: ''},
            // projectAdmin: project.projectAdmin
        });
        set_unitCostErrorMessage(null);
        set_errorMessage(null);

    }

    function editServiceDetails(rowData) {
        set_updateProjectServiceCost(rowData);
        updateServiceModalToggle();
        set_unitCostErrorMessage(null);
        set_errorMessage(null);
    }


    function onUpdateServiceTypeChange(curServiceType) {
        set_updateProjectServiceCost({...updateProjectServiceCost, serviceType: curServiceType});
    }

    function onServiceTypeChange(event) {
        set_projectServiceCost({...projectServiceCost, 'serviceType': {'stId': event.value}});
    }

    function onProjectServiceChange(e) {
        set_projectServiceCost(update(projectServiceCost, {comments: {$set: e.target.value}}));
    }

    function onProjectServiceUnit(e) {
        const {name, value} = e.target;

        if (value === '0') {
            set_errorMessage(true);

        } else if (/^0\d/.test(value)) {
            set_errorMessage(false);
            set_projectServiceCost(projectServiceCost_prev => {
                let totalCost = projectServiceCost_prev.unitCost * value.replace(/^0+/, '');
                return update(projectServiceCost_prev, {
                    totalCost: { $set: totalCost },
                    unitCount: { $set: value.replace(/^0+/, '') },
                });
            });
            /*set_projectServiceCost(prevState => {
                return { ...prevState, [name]: value.replace(/^0+/, '') };
            });*/

        } else {
            set_errorMessage(false);
            set_projectServiceCost(projectServiceCost_prev => {
                let totalCost = projectServiceCost_prev.unitCost * value;
                return update(projectServiceCost_prev, { totalCost: { $set: totalCost }, unitCount: { $set: value } });
            });
        }

    }

    function onProjectServiceUnitPerFee(e) {
        const {name, value} = e.target;

        if (value === '0') {
            set_unitCostErrorMessage(true);

        } else if (/^0\d/.test(value)) {
            set_unitCostErrorMessage(false);
            set_projectServiceCost(projectServiceCost_prev => {
                let totalCost = value.replace(/^0+/, '') * projectServiceCost_prev.unitCount;
                return update(projectServiceCost_prev, {
                    totalCost: { $set: totalCost },
                    unitCost: { $set: value.replace(/^0+/, '') },
                });
            });


        } else {
            set_unitCostErrorMessage(false);
            set_projectServiceCost(projectServiceCost_prev => {
                let totalCost = value * projectServiceCost_prev.unitCount;
                return update(projectServiceCost_prev, { totalCost: { $set: totalCost }, unitCost: { $set: value } });
            });
        }

    }

    function onProjectServiceUnitUpdate(e) {
        const {name, value} = e.target;

        if (value === '0') {
            set_errorMessage(true);

        } else if (/^0\d/.test(value)) {
            set_errorMessage(false);
            set_updateProjectServiceCost(updateProjectServiceCost_prev => {
                let totalCost = updateProjectServiceCost_prev.unitCost * value.replace(/^0+/, '');
                return update(updateProjectServiceCost_prev, {
                    totalCost: { $set: totalCost },
                    unitCount: { $set: value.replace(/^0+/, '') },
                });
            });

        } else {
            set_errorMessage(false);
            set_updateProjectServiceCost(updateProjectServiceCost_prev => {
                let totalCost = updateProjectServiceCost_prev.unitCost * value;
                return update(updateProjectServiceCost_prev, {
                    totalCost: { $set: totalCost },
                    unitCount: { $set: value },
                });
            });
        }

        /*set_updateProjectServiceCost(updateProjectServiceCost_prev => {
            let totalCost = updateProjectServiceCost_prev.unitCost * value;
            return update(updateProjectServiceCost_prev, {totalCost: {$set: totalCost}, unitCount: {$set: value}})
        });*/

    }

    function onProjectServiceUnitPerFeeupdate(e) {
        const {name, value} = e.target;

        if (value === '0') {
            set_unitCostErrorMessage(true);

        } else if (/^0\d/.test(value)) {
            set_unitCostErrorMessage(false);
            set_updateProjectServiceCost(updateProjectServiceCost_prev => {
                let totalCost = value.replace(/^0+/, '') * updateProjectServiceCost_prev.unitCount;
                return update(updateProjectServiceCost_prev, {
                    totalCost: { $set: totalCost },
                    unitCost: { $set: value.replace(/^0+/, '') },
                });
            });

        } else {
            set_unitCostErrorMessage(false);
            set_updateProjectServiceCost(updateProjectServiceCost_prev => {
                let totalCost = value * updateProjectServiceCost_prev.unitCount;
                return update(updateProjectServiceCost_prev, {
                    totalCost: { $set: totalCost },
                    unitCost: { $set: value },
                });
            });
        }


    }


    function onProjectServiceCostDetailsChange(event) {
        const {name, value} = event.target;
        set_updateProjectServiceCost({...updateProjectServiceCost, [name]: value});
    }

    async function findServiceTypeList() {
        await ServiceTypeController.findAll().then(result => {
            const serviceTypeList = result.map((serviceType) => {
                return {
                    ...serviceType, label: serviceType.name, value: serviceType.stId
                }
            })

            set_serviceTypeList(serviceTypeList);
        }).catch((error) => {
            console.error(error);

        }).finally(() => {

        });
    }

    function insertProjectCostService() {
        set_isAddBtnLoading(true)
        projectServiceCost.project = { projectId: project.projectId };
        if (userInfo.role.internalName === 'PM') {
            projectServiceCost.projectAdmin = project.projectAdmin;
        } else {
            projectServiceCost.projectAdmin = { paId: salesProjectAdmin.paId };
        }
        ProjectServiceCostController.insertProjectService(projectServiceCost).then(result => {
            toastify("success", t("common.component.project.service.details.service.added"), t("common.component.project.service.details.service.added"));
            set_projectServiceCost({
                unitCost: '',
                unitCount: '',
                totalCost: '',
                comments: '',
                // project: {projectId: project.projectId},
                serviceType: {stId: ''},
                // projectAdmin: project.projectAdmin
            });
            set_isAddBtnLoading(false)
        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            newServiceModalToggle();
            findProjectServiceByProject();
            findProjectById();
            set_isAddBtnLoading(false)
        });

    }

    function updateProjectServiceCostDetails() {
        set_isUpdateBtnLoading(true)
        const curProject = {projectId: project.projectId}
        const curPmProjectAdmin = { paId: project.projectAdmin.paId };
        const curSalesProjectAdmin = { paId: salesProjectAdmin.paId };

        const updateProjectServiceCostDetails = updateProjectServiceCost;
        updateProjectServiceCostDetails.project = curProject
        if (userInfo.role.internalName === 'PM') {
            updateProjectServiceCostDetails.projectAdmin = curPmProjectAdmin;
        } else {
            updateProjectServiceCostDetails.projectAdmin = curSalesProjectAdmin;
        }

        ProjectServiceCostController.updateProjectService(updateProjectServiceCostDetails).then(result => {
            toastify("success", t("common.component.project.service.details.service.updated"), t("common.component.project.service.details.service.updated"));
        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isUpdateBtnLoading(false)
            updateServiceModalToggle();
            findProjectServiceByProject();
            findProjectById();
        });
    }

    async function findProjectServiceByProject() {
        await ProjectServiceCostController.findByProject(project.projectId_ENCRYPTED).then(projectServiceCostList => {
            set_projectServiceList(projectServiceCostList);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
        });
    }

    const columns = useMemo(() => [{
        name: "Service",
        sortable: true,
        selector: rowData => rowData.serviceType.name,
        filter: (rowData, value) => rowData.serviceType.name.toLowerCase().includes(value.toLowerCase()),

    }, {
        name: "Fee Per Unit",
        sortable: true,
        selector: rowData => rowData.unitCost,
        filter: (rowData, value) => rowData.unitCost.toString().toLowerCase().includes(value.toLowerCase()),
    }, {
        name: "Projected Units",
        sortable: true,
        selector: rowData => rowData.unitCount,
        filter: (rowData, value) => rowData.unitCount.toString().toLowerCase().includes(value.toLowerCase()),
    }, {
        name: "Projected Cost",
        sortable: true,
        filter: (rowData, value) => rowData.totalCost.toLocaleString('en-IN').includes(value),
        selector: rowData => rowData.totalCost ? rowData.totalCost.toLocaleString('en-IN') : 0,
    }, {
        name: "Comments",
        sortable: true,
        selector: rowData => rowData.comments,
        filter: (rowData, value) => rowData.comments.toLowerCase().includes(value.toLowerCase()),
        cell: (rowData) => <div title={rowData.comments}>
            {rowData.comments}
        </div>
    }, {
        name: "", cell: (rowData) => <span>
                {userInfo.role.internalName !== 'MT' ? (<span>
                <Button className="next_outline_accent1_btn next_btn_lg"
                        disabled={project.projectStatus === "N"}
                        onClick={() => editServiceDetails(rowData)}> {t("common.component.project.service.details.btn.edit")}

                </Button>
            </span>) : (<span></span>)}
            </span>
    }]);

    useEffect(() => {

        async function fetchData() {
            await findProjectServiceByProject();
            await findServiceTypeList();
        }

        fetchData();

    }, []);


    return (<div>
        {/* ======================================    Add Est. Cost ===================================== */}

        <Modal
            isOpen={newServiceModal}
            toggle={newServiceModalToggle}
        >
            <ModalHeader
                toggle={closeServiceModalToggle}>{t("common.component.project.service.details.modal.new.service")}</ModalHeader>
            <ModalBody>
                <div>
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.service")}</label></Col>
                        <Col md={9}>
                            <Select
                                classNamePrefix="next_select"
                                options={serviceTypeList}
                                onChange={onServiceTypeChange}
                                placeholder={t("integr8.select.placeholder.text")}/>
                        </Col>

                    </Row>

                </div>

                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.fee.unit")}</label></Col>
                        <Col md={9}>
                            <Input type="number"
                                   min={1}
                                   name="unitCost"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   className="next_input"
                                   onChange={onProjectServiceUnitPerFee}
                                   placeholder={t("common.component.proj.clnt.cont.type.here")}/>
                            {unitCostErrorMessage ? (
                              <div
                                className="text-danger font-10">{t('integr8.projection.modal.error.msg')}</div>) : null}
                        </Col>

                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.project.unit")}</label></Col>
                        <Col md={9}>
                            <Input type="number"
                                   min={1}
                                   name="unitCount"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   className="next_input"
                                   onChange={onProjectServiceUnit}
                                   placeholder={t("common.component.proj.clnt.cont.type.here")}/>
                            {errorMessage ? (
                              <div
                                className="text-danger font-10">{t('integr8.projection.modal.error.msg')}</div>) : null}
                        </Col>

                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.total.cost")}</label></Col>
                        <Col md={9}>
                            <Input type="number"
                                   min={1}
                                   name="totalCost"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   disabled={true}
                                   value={projectServiceCost.totalCost}
                                   className="next_input"
                            />

                        </Col>

                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.comments")}</label>
                        </Col>
                        <Col md={9}>
                            <Input type="text" name="comments"
                                   className="next_input"
                                   onChange={onProjectServiceChange}
                                   placeholder={t("common.component.proj.clnt.cont.type.here")}/>
                        </Col>

                    </Row>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn "
                        onClick={closeServiceModalToggle}>
                    {t("pm.project.cancel.btn")}
                </Button>
                <ButtonWithLoader
                  disabled={disabilecostAnalysisBtn() || errorMessage === true || unitCostErrorMessage === true}
                    loading={isAddBtnLoading}
                    text={t('integr8.common.save.text')}
                    onClick={insertProjectCostService}
                    className="next_btn next_btn_lg"
                />


            </ModalFooter>

        </Modal>


        {/* ======================================  Update Est. Cost  ===================================== */}

        <Modal
            isOpen={updateServiceModal}
            toggle={updateServiceModalToggle}
        >
            <ModalHeader
                toggle={updateServiceModalToggle}>{t("pm.sow.update.service.text")}</ModalHeader>
            <ModalBody>
                <div>
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.service")}</label></Col>
                        <Col md={9}>
                            <Select
                                classNamePrefix="next_select"
                                options={serviceTypeList}
                                onChange={onUpdateServiceTypeChange}
                                defaultValue={!_.isEmpty(updateProjectServiceCost) ? {
                                    label: updateProjectServiceCost.serviceType.name,
                                    value: updateProjectServiceCost.serviceType.name
                                } : null}
                                // defaultValue={updateProjectServiceCost.serviceType}
                                placeholder={t("integr8.select.placeholder.text")}/>
                        </Col>

                    </Row>

                </div>

                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.fee.unit")}</label></Col>
                        <Col md={9}>
                            <Input type="number" name="unitCost" min={1}
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   value={updateProjectServiceCost.unitCost}
                                   className="next_input"
                                   onChange={onProjectServiceUnitPerFeeupdate}
                                   placeholder={t("common.component.proj.clnt.cont.type.here")}/>
                            {unitCostErrorMessage ? (
                              <div
                                className="text-danger font-10">{t('integr8.projection.modal.error.msg')}</div>) : null}
                        </Col>

                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.project.unit")}</label></Col>
                        <Col md={9}>
                            <Input type="number"
                                   min={1}
                                   name="unitCount"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   value={updateProjectServiceCost.unitCount}
                                   className="next_input"
                                   onChange={onProjectServiceUnitUpdate}
                                   placeholder={t("common.component.proj.clnt.cont.type.here")}/>
                            {errorMessage ? (
                              <div
                                className="text-danger font-10">{t('integr8.projection.modal.error.msg')}</div>) : null}
                        </Col>

                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.total.cost")}</label></Col>
                        <Col md={9}>
                            <Input type="number"
                                   min={1}
                                   name="totalCost"
                                   disabled={true}
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   value={updateProjectServiceCost.totalCost}
                                   className="next_input"
                            />
                        </Col>

                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-2">{t("common.component.project.service.details.comments")}</label>
                        </Col>
                        <Col md={9}>
                            <Input type="text" name="comments"
                                   value={updateProjectServiceCost.comments}
                                   className="next_input"
                                   onChange={onProjectServiceCostDetailsChange}
                                   placeholder={t("common.component.proj.clnt.cont.type.here")}/>
                        </Col>

                    </Row>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn "
                        onClick={updateServiceModalToggle}>
                    {t("pm.project.cancel.btn")}
                </Button>
                <ButtonWithLoader
                    loading={isUpdateBtnLoading}
                    disabled={_.isEmpty(updateProjectServiceCost.serviceType) || _.isEmpty(updateProjectServiceCost.unitCount) || _.isEmpty(updateProjectServiceCost.unitCost) || errorMessage === true || unitCostErrorMessage === true}
                    text={t('program.manager.individual.project.update.btn')}
                    onClick={updateProjectServiceCostDetails}
                    className="next_btn next_btn_lg"
                />


            </ModalFooter>

        </Modal>

        <div className="feedback_table containt_e2e-datatable mt-3">
            <DataTableView
                title={t("pm.close.projects.title")}
                columns={columns}
                data={_.orderBy(projectServiceCostList, ['genDate'], ['desc'])}
                options={{search: true, extendedSearch: false, pagination: true}}
            />
        </div>
    </div>);

}

export default PmMtProjectInvoice;