import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import _ from 'lodash';
import BillingInstructionController from '../../../api/controller/billinginstruction-controller';
import Moment from 'react-moment';
import { getSoupText } from '../../../util/text-util';
import update from 'immutability-helper';
import { basicSunEditorListTableOptions } from '../../../config/suneditor-config';
import { sunEditorExtraOptions } from '../../../config/suneditor-options';
import conf_prop from '../../../properties/properties';
import { UpdatePhoto } from '../../../pages/common-components/user-photo';
import classNames from 'classnames';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';


function PmMtBillingInstructionsTab({ project, userInfo, salesProjectAdmin }) {

  const [isPostBtnLoading, set_isPostBtnLoading] = useState(false);
  const [isLoaded, set_isLoaded] = useState(false);
  const { t } = useTranslation();
  const [files, set_files] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [billingInstructions, set_billingInstructions] = useState([]);
  const [fileEmpty, set_fileEmpty] = useState('');
  const [fileAttachedStatus, set_fileAttachedStatus] = useState(false);
  const [billingInstructionList, set_billingInstructionList] = useState([]);
  const [instructionPage, set_instructionPage] = useState({});
  const [page, set_page] = useState(0);

  const [billingInstruction, set_billingInstruction] = useState({
    billingInstructionText: '',

  });

  const soupText = getSoupText(billingInstruction.billingInstructionText);

  const editorRef = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  function onbillingInstructionChange(e) {
    set_billingInstruction(billingInstruction_prev => update(billingInstruction_prev, { 'billingInstructionText': { $set: e } }));
  }

  function handleChangeTab(activeTabInput) {
    setActiveTab(activeTabInput);
  }


  function resetFile() {
    set_fileAttachedStatus(false);
    set_files([]);
    set_fileEmpty(Date.now());
  }

  // async function findBillingInstructionsByProject() {
  //     await BillingInstructionController.findByProject(project.projectId_ENCRYPTED).then(billingInstructions => {
  //         set_billingInstructions(billingInstructions);
  //     }).catch((error) => {
  //         console.error(error);
  //     });
  // }

  async function findBillingInstructionsByProject(page) {
    const pageSize = 5;
    const tempPage = page;
    await BillingInstructionController.findBillingInstructionsByProject(project.projectId_ENCRYPTED, tempPage, pageSize).then(results => {
      if (page === 0) {
        set_billingInstructionList(results.content);
      } else {
        set_billingInstructionList(instruction_prev => {
          const billingInstructions_input = [...instruction_prev, ...results.content];
          return _.uniqBy(billingInstructions_input, function(billinginstruction) {
            return billinginstruction.biId;
          });
        });
      }
      set_instructionPage(results);
      set_page(page_prev => page_prev + 1);
    }).catch(error => {
      console.error(error);
    });
  }

  async function refreshBillingInstructions() {
    set_billingInstructionList([]);
    const pageInput = 0;
    await findBillingInstructionsByProject(pageInput);
  }

  function handleFileState(e) {
    set_files(e.target.files);
    set_fileAttachedStatus(true);
    //e.target.value = null;
  }


  async function submitBillingInstruction() {

    set_isPostBtnLoading(true);
    billingInstruction.project = { projectId: project.projectId };
    if (userInfo.role.internalName === 'PM') {
      billingInstruction.projectAdmin = project.projectAdmin;
    } else {
      billingInstruction.projectAdmin = { paId: salesProjectAdmin.paId };
    }


    if (!_.isEmpty(files)) {
      let formData = new FormData();
      formData.append('billingInstructionJson', JSON.stringify(billingInstruction));
      for (let i = 0; i < files.length; i++) {
        formData.append('uploadedFile', files[i]);
      }
      await BillingInstructionController.insertBillingInstructionWithFile(formData).then(result => {

        toastify('success', t('program.manager.billing.view.saved.successfully.msg'), t('program.manager.billing.view.saved.successfully.msg'));
        set_billingInstructions(billingInstructions_prev => billingInstructions_prev.concat(result));
        set_files([]);
        editorRef.current.setContents('');

      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        set_isPostBtnLoading(false);
        refreshBillingInstructions();
        resetFile();

      });
    } else {
      await BillingInstructionController.insertBillingInstruction(billingInstruction).then(result => {

        toastify('success', t('program.manager.billing.view.saved.successfully.msg'), t('program.manager.billing.view.saved.successfully.msg'));
        set_billingInstructions(billingInstructions_prev => billingInstructions_prev.concat(result));
        editorRef.current.setContents('');

      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        set_isPostBtnLoading(false);
        refreshBillingInstructions();

      });
    }

  }


  useEffect(() => {
    set_isLoaded(false);

    async function fetchData() {
      set_isLoaded(true);
      await findBillingInstructionsByProject();
    }

    fetchData();

  }, []);


  return (<div>

    <div>
      {userInfo.role.internalName !== 'MT' ? (<div>
        <div>
          {project.projectStatus === 'Y' ? (<div>
            <div className="mt-2">
              <SunEditor
                placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                name="pageDesc"
                setOptions={{
                  ...sunEditorExtraOptions,
                  mode: 'classic',
                  height: '150px',
                  videoWidth: '100%',
                  videoHeight: '100%',
                  resizingBar: true,
                  videoHeightShow: false,
                  videoRatioShow: false,
                  videoResizing: false,
                  showPathLabel: false,
                  videoSizeOnlyPercentage: false,
                  imageFileInput: false,
                  'buttonList': [basicSunEditorListTableOptions],
                }}
                autoFocus={false}
                // hideToolbar={true}
                onChange={onbillingInstructionChange}
                getSunEditorInstance={getSunEditorInstance}

                setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
              />
            </div>

            <Row className="mt-3">
              <Col md={2} className={'pe-0'}>
                <div className={'integr8_file_input mt-1'}>
                  <label className={'text-info'} htmlFor="fileUpload"
                         style={{ cursor: 'pointer' }}><i
                    className="fas fa-paperclip me-1"></i>{t('integr8.common.attach.file.btn')}
                  </label>

                  <input type="file"
                         id="fileUpload"
                         className="next_file_input mt-4"
                    // files={files}
                         onChange={handleFileState}
                         key={fileEmpty || ''}
                  />
                </div>
              </Col>
              <Col md={8} className={'ps-0'}>
                <div className={'mt-1'} style={{ marginLeft: '-3rem' }}>
                  {files.length > 0 && (<span
                    onClick={resetFile}>{files[0]?.name}<i
                    className="fas fa-trash-alt ms-2" /> </span>)}
                </div>
              </Col>
              <Col md={2}>
                <div className="text-end">
                  <ButtonWithLoader
                    className="next_btn next_btn_lg "
                    disabled={_.isEmpty(soupText)}
                    loading={isPostBtnLoading}
                    onClick={submitBillingInstruction}
                    text={t('integr8.post.btn')}
                  >
                  </ButtonWithLoader>
                </div>
              </Col>
            </Row>

          </div>) : (<span></span>)}
        </div>

      </div>) : (<div></div>)}
    </div>

    <br />

    <div className="mt-2 mb-3 tw-font-bold">
      {t('program.manager.billing.view.heading.billing')}</div>
    {!_.isEmpty(billingInstructionList) &&
      (<div className="mt-3">

        {billingInstructionList.map((billingInstruction, index) => (<div>
            <Row className="mb-2">
              {!_.isEmpty(userInfo.mimeType) ? <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                <UpdatePhoto
                  userInfo={billingInstruction.projectAdmin.userInfo} />
              </Col> : <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                <img className="round" alt={'user-photo'}
                     src="https://cdn.thinktalentws48.click/image/user_default.png" />
              </Col>}
              <Col lg="10" md="10" sm="11" xs="11" className="ps-0">
                <div className="mt-1">
                  <div className="font-14">
                    {billingInstruction.projectAdmin.userInfo.name}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    <Moment format="DD/MM/YYYY hh:ss a">{billingInstruction.genDate}</Moment>
                  </div>
                </div>
              </Col>
            </Row>


            <div className="mb-1 comment-section">
              {!_.isEmpty(billingInstruction) ? (<div className={'comment'}
                                                      dangerouslySetInnerHTML={{ __html: billingInstruction.billingInstructionText }} />) : ''}
            </div>
            <div>
              {!_.isNull(billingInstruction.fileName) &&
                <div className="font-13 fw-bold pb-3"
                     style={{ color: '#37A4FE', marginTop: '-10px' }}>
                  <a
                    href={conf_prop.get('integr8ServiceUrl') + '/rest/billinginstruction/billing-download/' + billingInstruction.biId_ENCRYPTED}>
                    <i className="ti-download me-2" style={{ color: '#37A4FE' }}></i>
                    {t('finance.billing.inst.download.file')}
                  </a>
                </div>}

            </div>
          </div>
        ))}


      </div>)}
    <div className="text-center mt-4 next_wall_btn">
      <Button
        disabled={instructionPage.last}

        color={'link'}
        className={classNames({ 'disabled-link': instructionPage.last })}
        style={{ cursor: 'pointer' }}
        onClick={() => findBillingInstructionsByProject(page)}
      >
        <div>{t(`integr8.update.button.text.load.more`)}</div>
        <div><i className="ti-angle-down mt-2"></i></div>
      </Button>
    </div>


  </div>);
}

export default PmMtBillingInstructionsTab;