import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Card, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SowInvoiceController from '../../../api/controller/sowinvoice-controller';
import InvoiceDocumentTrackController from '../../../api/controller/invoiceDocumentTrack-controller';
import Moment from 'react-moment';
import img1 from '../../../assets/images/users/project-value.png';
import img2 from '../../../assets/images/users/projected-cost.png';
import img3 from '../../../assets/images/users/amount-invoiced.png';
import img4 from '../../../assets/images/users/paidbyclient.png';
import ProjectController from '../../../api/controller/project-controller';
import ApiUtils from '../../../api/ApiUtils';
import update from 'immutability-helper';
import { Rating } from 'react-simple-star-rating';
import { sowInvoicePmApprovalAcceptSchema } from '../../../validation/sow-invoice-rating-validation';
import { useLocation, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';

// import StarRatings from "react-star-ratings/build/star-ratings";


function PmMtPartnerFinanceViewInvoice() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const extraParam = queryParams.get('extraParam');
    let userInfo = ApiUtils.getLocalStorage("userInfo");
    const {t} = useTranslation();
    const path_params = useParams();
    const {sowiId_ENCRYPTED} = path_params;
    const [isLoaded, set_isLoaded] = useState(false);
    const [sowInvoice, set_sowInvoice] = useState({});
    const [invoiceNumber, set_invoiceNumber] = useState(null);
    const [partnerName, set_partnerName] = useState(null);
    const [mtMemberName, set_mtMemberName] = useState(null);
    const [projectCode, set_projectCode] = useState(null);
    const [sowNumber, set_sowNumber] = useState(null);
    const [projectSow, set_projectSow] = useState({});
    const [userBilling, set_userBilling] = useState({});
    const [invoiceDocumentTrackList, set_invoiceDocumentTrackList] = useState([]);
    const [financePaymentTrackList, set_financePaymentTrackList] = useState([]);
    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [projectDetailsSowModal, set_projectDetailsSowModal] = useState(false);
    const [project, set_project] = useState({});
    const [pMAcceptInvoiceModal, set_pMAcceptInvoiceModal] = useState(false);
    const [pMRejectInvoiceModal, set_pMInvoiceRejectModal] = useState(false);
    const [mTRejectInvoiceModal, set_mTRejectInvoiceModal] = useState(false);
    const [mTAcceptInvoiceModal, set_mTAcceptInvoiceModal] = useState(false);
    const [hsn, set_hsn] = useState("998311");
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [pmProjectInvoice, set_pMProjectInvoice] = useState({
        invoiceDocumentTracks: [{"comment": ''}],
        'sowiId': '',
        "pmPartnerInvoiceRatingComment": '',
        "pmPartnerInvoiceRating": null,
        'projectSow': {'project': {'projectAdmin': {'paId': ''}}},
    });

    const [mtProjectInvoice, set_mTProjectInvoice] = useState({
        invoiceDocumentTracks: [{"comment": ''}], 'sowiId': '', 'projectSow': {'projectAdminMt': {'paId': ''}},
    });

    const schema = useMemo(() => sowInvoicePmApprovalAcceptSchema, []);

    function disableApprovalBtn() {
        return !schema.isValidSync(pmProjectInvoice);
    }


    function onChangeRating(newRating) {
        set_pMProjectInvoice(update(pmProjectInvoice, {
            pmPartnerInvoiceRating: {$set: newRating}
        }));
    }

    function onPmStarRatingCommentChange(e) {
        const comment = e.target.value;
        set_pMProjectInvoice(update(pmProjectInvoice, {
            pmPartnerInvoiceRatingComment: {$set: comment}
        }));
    }

    function togglePMAcceptInvoiceModal() {
        set_pMAcceptInvoiceModal((pMAcceptInvoiceModalPrev) => {
            return !pMAcceptInvoiceModalPrev;
        })
    }

    function togglePMInvoiceRejectModal() {
        set_pMInvoiceRejectModal((invoiceRejectModalPrev) => {
            return !invoiceRejectModalPrev;
        })
    }

    function toggleMTAcceptInvoiceModal() {
        set_mTAcceptInvoiceModal((mTAcceptInvoiceModalPrev) => {
            return !mTAcceptInvoiceModalPrev;
        })
    }

    function toggleMTInvoiceRejectModal() {
        set_mTRejectInvoiceModal((mTRejectInvoiceModalPrev) => {
            return !mTRejectInvoiceModalPrev;
        })
    }

    function onMtCommentChange(e) {
        const comment = e.target.value;
        set_mTProjectInvoice(update(mtProjectInvoice, {
            invoiceDocumentTracks: {
                0: {
                    comment: {$set: comment}
                }
            }
        }));
    }

    function mtRejectSowInvoice() {
        set_isUpLoaded(true);
        mtProjectInvoice.projectSow.projectAdminMt.paId = sowInvoice.projectSow.projectAdminMt.paId
        mtProjectInvoice.sowiId = sowInvoice.sowiId
        SowInvoiceController.updateMTRejectSowInvoiceStatus(mtProjectInvoice).then((response) => {
            set_isUpLoaded(false);
            toastify("error", t("mt.invoice.reject.msg"), t("mt.invoice.reject.msg"));
            toggleMTInvoiceRejectModal();
            if (!_.isNull(extraParam)) {
                window.location.href = `/integr8/management/individual-project/${sowInvoice.projectSow.project.projectId_ENCRYPTED}#2`;
            } else {
                window.location.href = `/integr8/management/approval#2`;
            }
        }).catch((error) => {
            console.error(error);
            set_isUpLoaded(false);
        })
    }

    function mtAcceptSowInvoice() {
        set_isUpLoaded(true);
        mtProjectInvoice.projectSow.projectAdminMt.paId = sowInvoice.projectSow.projectAdminMt.paId
        mtProjectInvoice.sowiId = sowInvoice.sowiId
        SowInvoiceController.updateMTApproveSowInvoiceStatus(mtProjectInvoice).then((response) => {
            set_isUpLoaded(false);
            toastify("success", t("mt.approve.invoice.accepted"), t("mt.approve.invoice.accepted"));
            toggleMTAcceptInvoiceModal();
            if (!_.isNull(extraParam)) {
                window.location.href = `/integr8/management/individual-project/${sowInvoice.projectSow.project.projectId_ENCRYPTED}#2`;
            } else {
                window.location.href = `/integr8/management/approval#2`;
            }
        }).catch((error) => {
            console.error(error);
            set_isUpLoaded(false);
        })
    }

    function onPmCommentChange(e) {
        const comment = e.target.value;
        set_pMProjectInvoice(update(pmProjectInvoice, {
            invoiceDocumentTracks: {
                0: {
                    comment: {$set: comment}
                }
            }
        }));
    }

    function pMRejectSowInvoice() {
        set_isUpLoaded(true);
        pmProjectInvoice.projectSow.project.projectAdmin.paId = sowInvoice.projectSow.project.projectAdmin.paId
        pmProjectInvoice.sowiId = sowInvoice.sowiId

        SowInvoiceController.updatePMRejectSowInvoiceStatus(pmProjectInvoice).then((response) => {
            set_isUpLoaded(false);
            toastify("error", t("program.manager.view.invoice.rejected.invoice"), t("program.manager.view.invoice.rejected.invoice"));
            togglePMInvoiceRejectModal();
            findInvoice();
            findInvoiceDocumentTrackList();
            if (!_.isNull(extraParam)) {
                window.location.href = `/integr8/program-manager/individual-project/${sowInvoice.projectSow.project.projectId_ENCRYPTED}#2`;
            } else {
                window.location.href = `/integr8/program-manager/approvals`;
            }
        }).catch((error) => {
            console.error(error);
            set_isUpLoaded(false);
        })
    }

    function pMAcceptSowInvoice() {
        set_isUpLoaded(true);
        pmProjectInvoice.projectSow.project.projectAdmin.paId = sowInvoice.projectSow.project.projectAdmin.paId
        pmProjectInvoice.sowiId = sowInvoice.sowiId

        SowInvoiceController.updatePMApproveSowInvoiceStatus(pmProjectInvoice).then((response) => {
            set_isUpLoaded(false);
            toastify("success", t("program.manager.view.invoice.approved.invoice"), t("program.manager.view.invoice.approved.invoice"));
            togglePMAcceptInvoiceModal();
            findInvoice();
            findInvoiceDocumentTrackList();
            if (!_.isNull(extraParam)) {
                window.location.href = `/integr8/program-manager/individual-project/${sowInvoice.projectSow.project.projectId_ENCRYPTED}#2`;
            } else {
                window.location.href = `/integr8/program-manager/approvals`;
            }
        }).catch((error) => {
            console.error(error);
            set_isUpLoaded(false);
        })
    }


    function toggleProjectDetailsSowModal() {
        {
            !_.isEmpty(projectSow) && findProjectById();
        }

        set_projectDetailsSowModal((projectDetailsSowModalPrev) => {
            return !projectDetailsSowModalPrev;
        });
    }

    async function findInvoice() {
        await SowInvoiceController.findById(sowiId_ENCRYPTED).then((sowInvoice) => {
            const invoiceNumber = "INV" + sowInvoice.sowiId
            const partnerName = sowInvoice.projectSow.projectAdminPartner.userInfo.name
            const mtMemberName = sowInvoice.projectSow.projectAdminMt.userInfo.name
            const projectCode = sowInvoice.projectSow.project.projectCode
            const sowNumber = "SOW" + sowInvoice.projectSow.psowId
            const projectSow = sowInvoice.projectSow
            const userBilling = _.head(sowInvoice.projectSow.projectAdminPartner.userInfo.userBillings)

            set_sowInvoice(sowInvoice);
            set_invoiceNumber(invoiceNumber);
            set_partnerName(partnerName);
            set_mtMemberName(mtMemberName);
            set_projectCode(projectCode);
            set_sowNumber(sowNumber);
            set_projectSow(projectSow);
            set_userBilling(userBilling);
            set_isLoaded(true);

        }).catch((error) => {
            console.error(error);
        });
    }

    async function findInvoiceDocumentTrackList() {
        await InvoiceDocumentTrackController.findBySowInvoice(sowiId_ENCRYPTED).then(results => {
            set_isLoaded(true);
            set_invoiceDocumentTrackList(results.filter((track) => track.invoiceStatus !== "FinanceApproved"))
            set_financePaymentTrackList(results.filter((track) => track.invoiceStatus === "FinanceApproved"))
            set_isUpLoaded(false);
        }).catch(error => {
            console.error(error)
        })
    }

    async function findProjectById() {
        await ProjectController.findById(projectSow.project.projectId_ENCRYPTED)
            .then(project => {
                set_project(project)
            }).catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        set_isLoaded(false);

        async function fetchData() {
            await findInvoice();
            await findInvoiceDocumentTrackList();
            set_isLoaded(true);
        }

        fetchData();

    }, []);


    return (<div className={'p-3'}>

        {/*<Row>
            <Col md={12}>
                <div className={'font-14'}>
            <span className="me-1">
              <i className="fas fa-angle-left"></i>
            </span>
                    {userInfo.role.internalName === "PM" ? (<Link
                        to={`${conf_prop.get("route_path")}/program-manager/individual-project/${!_.isEmpty(sowInvoice) && sowInvoice.projectSow.project.projectId_ENCRYPTED}#2`}
                        className="text_next_color"
                    >
                        {t('integr8.back.btn')}
                    </Link>) : userInfo.role.internalName === "MT" ? (<Link
                            to={`${conf_prop.get("route_path")}/management/individual-project/${!_.isEmpty(projectSow) && projectSow.project.projectId_ENCRYPTED}#2`}
                            className="text_next_color"
                        >
                            {t('integr8.back.btn')}
                        </Link>

                    ) : userInfo.role.internalName === "PARTNER" ? (<Link
                        to={`${conf_prop.get("route_path")}/partner/partner-individual-projects/${!_.isEmpty(sowInvoice) && sowInvoice.projectSow.project.projectId_ENCRYPTED}#3`}
                        className="text_next_color"
                    >
                        {t('integr8.back.btn')}
                    </Link>) : (<Link
                        to={`${conf_prop.get("route_path")}/finance/payments`}
                        className="text_next_color"
                    >{t('integr8.back.btn')}</Link>)}

                </div>
            </Col>
        </Row>*/}


        {/*===================================================================================================================*/}

        <Row className="mb-3 mt-2">
            <Col xs={12} md={6}>
                <h4 className="d-inline-block">
                    <b>{t('program.manager.view.invoice.number.invoice')} {invoiceNumber}</b>
                </h4>
                <button type="button" className="btn btn-link mb-1" onClick={handlePrint}>
                    <i className="fa fa-print" aria-hidden="true"></i>
                </button>
            </Col>

            <Col xs={12} md={6}>
                <div className="text-md-end text-center mt-3 mt-md-0">
                    {(userInfo.role.internalName === 'PM' || userInfo.role.internalName === 'MT' || userInfo.role.internalName === 'SALES') && (
                        <Button className="next_outline_accent1_btn next_btn_lg" onClick={toggleProjectDetailsSowModal}>
                            {t('integr8.sow.project.details.btn')}
                        </Button>)}

                    {userInfo.role.internalName === 'PM' && sowInvoice.invoiceStatus === 'PInitiated' && (<>
                        <Button className="next_accent2_btn next_btn_lg ms-2" onClick={togglePMInvoiceRejectModal}>
                            {t('program.manager.view.invoice.reject.btn')}
                        </Button>
                        <Button className="next_btn_success_integr8 next_btn_lg ms-2"
                                onClick={togglePMAcceptInvoiceModal}>
                            {t('program.manager.view.invoice.approve.btn')}
                        </Button>
                    </>)}

                    {userInfo.role.internalName === 'MT' && mtMemberName === userInfo.name && sowInvoice.invoiceStatus === 'PMApproved' && (<>
                        <Button className="next_accent2_btn next_btn_lg ms-2" onClick={toggleMTInvoiceRejectModal}>
                            {t('program.manager.view.invoice.reject.btn')}
                        </Button>
                        <Button className="next_btn_success_integr8 next_btn_lg ms-2"
                                onClick={toggleMTAcceptInvoiceModal}>
                            {t('program.manager.view.invoice.approve.btn')}
                        </Button>
                    </>)}
                </div>
            </Col>
        </Row>

        <Row>
            <Col xs={12} md={6}>
                <div className="pb-2"><b>{t('program.manager.view.invoice.header.think.talent')}</b></div>
                <Card className="border next_radious mt-1">
                    <div>
                        <Row className="invoice_body">
                            <Col xs={12} md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{t("mt.view.invoice.address")}</div>
                            </Col>
                            <Col xs={12} md={9} className="pt-3 pb-3">
                                <div>{t("mt.view.invoice.address.details")}</div>
                            </Col>
                        </Row>
                        <Row className="invoice_body">
                            <Col md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{t("mt.view.invoice.email")}</div>
                            </Col>
                            <Col md={9} className="pt-3 pb-3">
                                <div>{t("mt.view.invoice.email.id")}</div>
                            </Col>
                        </Row>

                        <Row className="invoice_body">
                            <Col md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{t("mt.view.invoice.phone")}</div>
                            </Col>
                            <Col md={9} className="pt-3 pb-3">
                                <div>{t("mt.view.invoice.phone.number")}</div>
                            </Col>
                        </Row>

                        <Row className="invoice_body">
                            <Col md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{t("mt.view.invoice.gstin")}</div>
                            </Col>
                            <Col md={9} className="pt-3 pb-3">
                                <div>{t("mt.view.invoice.gstin.number")}</div>
                            </Col>
                        </Row>

                        <Row className="invoice_body">
                            <Col md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{t("mt.view.invoice.state")}</div>
                            </Col>
                            <Col md={9} className="pt-3 pb-3">
                                <div>{t("mt.view.invoice.state.text")}</div>
                            </Col>
                        </Row>

                        <Row className="issue_SOW_body">
                            <Col md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{t("mt.view.invoice.cin")}</div>
                            </Col>
                            <Col md={9} className="pt-3 pb-3">
                                <div>{t("mt.view.invoice.cin.number")}</div>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Col>

            <Col xs={12} md={6}>
                <div className="pb-2"><b>{t("program.manager.view.invoice.title.partner.details")}</b></div>
                <Card className="border next_radious mt-1">
                    <Row className="invoice_body">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("mt.view.invoice.partner.name")}</div>
                        </Col>
                        <Col md={9} className="pt-3 pb-3">
                            <div>{partnerName}</div>
                        </Col>
                    </Row>

                    <Row className="invoice_body">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("mt.view.invoice.partner.address")}</div>
                        </Col>
                        {!_.isEmpty(userBilling) ? (<Col md={9} className="pt-3 pb-3">
                            <div>{userBilling.billingAddress}</div>
                        </Col>) : (<Col md={9} className="pt-3 pb-3">
                            <div>-</div>
                        </Col>)}

                    </Row>
                    <Row className="invoice_body">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("mt.view.invoice.project.code")}</div>
                        </Col>
                        <Col md={9} className="pt-3 pb-3">
                            <div>{projectCode}</div>
                        </Col>
                    </Row>
                    <Row className="invoice_body">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("mt.view.invoice.sow.number")}</div>
                        </Col>
                        <Col md={9} className="pt-3 pb-3">
                            <div>{sowNumber}</div>
                        </Col>
                    </Row>

                    <Row className="invoice_body">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div className="mt-2">{t("mt.view.invoice.gst.applicable")}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3 invoice_body_border">
                            {!_.isNull(sowInvoice.gstStatus) ? (<div className="mt-2">{sowInvoice.gstStatus}</div>) : (
                                <div className="mt-2">No</div>)}
                        </Col>
                        {sowInvoice.gstStatus === "Yes" && <Col md={7} className="pt-3 pb-3 invoice_body_border">
                            <div className={'mt-2'}>{_.isEmpty(userBilling) ? "-" : userBilling.gstNo}</div>
                        </Col>}
                        {(sowInvoice.gstStatus === "No" || _.isNull(sowInvoice.gstStatus)) &&
                            <Col md={7} className="pt-3 pb-3 invoice_body_border">
                                <div className={'mt-2'}>-</div>
                            </Col>}

                    </Row>


                    <Row className="issue_SOW_body">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("mt.view.invoice.partner.ref.number")}</div>
                        </Col>
                        {!_.isEmpty(sowInvoice.partnerRefNumber) ? (<Col md={9} className="pt-3 pb-3">
                            <div>{sowInvoice.partnerRefNumber}</div>
                        </Col>) : (<Col md={9} className="pt-3 pb-3">
                            <div>-</div>
                        </Col>)}
                    </Row>
                </Card>
            </Col>
        </Row>

        <Row>
            <Col xs={12}>
                <Card className="border next_radious mt-1">
                    <Row className="issue_SOW_heading">
                        <Col md={3} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("integr8.view.invoice.description")}</div>
                        </Col>
                        <Col md={1} className="pt-3 pb-3 invoice_body_border">
                            <div>{t("integr8.invoice.hsn.sac")}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3 invoice_body_border text-center">
                            <div>{t("integr8.view.invoice.unit.assigned")}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3 invoice_body_border text-center">
                            <div>{t("integr8.view.invoice.fee")}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3 invoice_body_border text-center">
                            <div>{t("integr8.view.invoice.units")}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3 text-center">
                            <div>{t("integr8.view.invoice.amount")}</div>
                        </Col>
                    </Row>
                    {!_.isEmpty(sowInvoice.sowInvoiceDescriptions) && sowInvoice.sowInvoiceDescriptions.map((sowInvoiceDescription, sowInvoiceDescriptionIndex) =>
                        <Row className="issue_SOW_body" key={sowInvoiceDescriptionIndex}>
                            <Col md={3} className="pt-3 pb-3 invoice_body_border">
                                <div>{sowInvoiceDescription.sowDescription.description}</div>
                            </Col>
                            <Col md={1} className="pt-3 pb-3 invoice_body_border">
                                <div>{hsn}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3 invoice_body_border">
                                <div className={'text-center'}>{sowInvoiceDescription.sowDescription.unit}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3 invoice_body_border">
                                <div
                                    className={'text-center'}>{sowInvoiceDescription.sowDescription.fee.toLocaleString('en-IN')}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3 invoice_body_border">
                                <div className={'text-center'}>{sowInvoiceDescription.unit}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3">
                                <div
                                    className={'text-center'}>{sowInvoiceDescription.amount.toLocaleString('en-IN')}</div>
                            </Col>
                            <hr style={{margin: '0px'}}/>
                        </Row>)}
                    <Row className="issue_SOW_body">
                        <Col md={10} className="pt-3 pb-3 invoice_body_border">
                            <div className={'text-end'}
                                 style={{paddingRight: '5%'}}>{t('partner.ptnr.invoice.desc.sub.total')}</div>
                        </Col>
                        <Col md={2} className="pt-3 pb-3">
                            <div className={'text-center'}>{sowInvoice?.subTotalAmt?.toLocaleString('en-IN') || 0}</div>
                        </Col>
                        <hr style={{margin: '0px'}}/>
                    </Row>

                    {sowInvoice?.gstStatus === "Yes" && (<div>
                        <Row className="issue_SOW_body">
                            <Col md={10} className="pt-3 pb-3 invoice_body_border">
                                <div className={'text-end'}
                                     style={{paddingRight: '5%'}}>{t('partner.ptnr.invoice.desc.sub.gst')}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3">
                                <div className={'text-center'}>{sowInvoice?.gstAmt?.toLocaleString('en-IN') || 0}</div>
                            </Col>
                            <hr style={{margin: '0px'}}/>
                        </Row>
                        <Row className="issue_SOW_body">
                            <Col md={10} className="pt-3 pb-3 invoice_body_border">
                                <div className={'text-end'}
                                     style={{paddingRight: '5%'}}>{t('partner.ptnr.invoice.desc.total.amount')}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3">
                                <div
                                    className={'text-center'}>{sowInvoice?.totalAmt?.toLocaleString('en-IN') || 0}</div>
                            </Col>

                        </Row>
                    </div>)}

                    {(sowInvoice?.gstStatus === "No" || sowInvoice?.gstStatus === null) && (
                        <Row className="issue_SOW_body">
                            <Col md={10} className="pt-3 pb-3 invoice_body_border">
                                <div className={'text-end'}
                                     style={{paddingRight: '5%'}}>{t('partner.ptnr.invoice.desc.total.amount')}</div>
                            </Col>
                            <Col md={2} className="pt-3 pb-3">
                                <div
                                    className={'text-center'}>{sowInvoice?.subTotalAmt?.toLocaleString('en-IN') || 0}</div>
                            </Col>

                        </Row>)}
                </Card>
            </Col>
        </Row>
        <small>{t("program.manager.view.invoice.note.tds.deduction")}</small>
        <Row className="mt-4">
            <Col xs={12}>
                {!_.isEmpty(invoiceDocumentTrackList) ? (<div>
                    <div className="pb-2"><b>{t('program.manager.view.invoice.document.track')}</b></div>
                    <Card className={'border next_radious mt-1'}>
                        <Row className="issue_SOW_heading">
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('program.manager.view.invoice.status')}</div>
                            </Col>
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('pm.sow.table.th.user')}</div>
                            </Col>
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('pm.sow.table.th.date')}</div>
                            </Col>
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('program.manager.view.invoice.comments')}</div>
                            </Col>
                        </Row>

                        {invoiceDocumentTrackList.map((invoiceDocumentTrack, swtIndex) => (
                            <Row className="issue_SOW_body" key={swtIndex}>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div>
                                        {invoiceDocumentTrack.invoiceStatus === 'PIssued' &&
                                            <span>{t('program.manager.invoice.status.comment.pissued.text')}</span>}
                                        {invoiceDocumentTrack.invoiceStatus === 'PInitiated' &&
                                            <span>{t('program.manager.invoice.status.comment.pinitiated.text')}</span>}
                                        {invoiceDocumentTrack.invoiceStatus === 'PMApproved' &&
                                            <span>{t('program.manager.invoice.status.comment.pmapproved.text')}</span>}
                                        {invoiceDocumentTrack.invoiceStatus === 'PMRejected' &&
                                            <span>{t('program.manager.invoice.status.comment.pmrejected.text')}</span>}
                                        {invoiceDocumentTrack.invoiceStatus === 'MTApproved' &&
                                            <span>{t('program.manager.invoice.status.comment.mtapproved.text')}</span>}
                                        {invoiceDocumentTrack.invoiceStatus === 'MTRejected' &&
                                            <span>{t('program.manager.invoice.status.comment.mtrejected.text')}</span>}
                                    </div>
                                </Col>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div>{!_.isEmpty(invoiceDocumentTrack.projectAdmin.userInfo) ? invoiceDocumentTrack.projectAdmin.userInfo.name : ''}</div>
                                </Col>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div><Moment local titleFormat="D MMM YYYY" format="D MMM YYYY"
                                                 withTitle>{invoiceDocumentTrack.timestamp}</Moment></div>
                                </Col>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div>{!_.isEmpty(invoiceDocumentTrack.comment) ? (
                                        <span>{invoiceDocumentTrack.comment}</span>) : (<span>-</span>)}</div>
                                </Col>
                                {!_.isEmpty(invoiceDocumentTrackList) && invoiceDocumentTrackList.length - 1 !== swtIndex &&
                                    <hr style={{marginBottom: '0'}}/>}
                            </Row>))}
                    </Card>
                </div>) : (<div></div>)}
            </Col>
        </Row>

        <Row className="mt-4">
            <Col xs={12}>
                {!_.isEmpty(financePaymentTrackList) ? (<div>
                    <div className="pb-2"><b>{t('program.manager.view.invoice.payment.track')}</b></div>
                    <Card className={'border next_radious mt-1'}>
                        <Row className="issue_SOW_heading">
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('program.manager.view.invoice.status')}</div>
                            </Col>
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('pm.sow.table.th.date')}</div>
                            </Col>
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('program.manager.project.actual.rev.amount')}</div>
                            </Col>
                            <Col xs={6} sm={3} className="pt-3 pb-3">
                                <div>{t('program.manager.view.invoice.comments')}</div>
                            </Col>
                        </Row>

                        {financePaymentTrackList.map((financePaymentTrack, swtIndex) => (
                            <Row className="issue_SOW_body" key={swtIndex}>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div>
                                        {financePaymentTrack.invoicePaymentProgressStatus === 'FullyPaid' ? (
                                            <span>{t('integr8.finance.partner.payment.fully.paid.btn')}</span>) : (
                                            <span>{t('integr8.finance.partner.payment.partially.paid.btn')}</span>)}
                                    </div>
                                </Col>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div><Moment local titleFormat="D MMM YYYY" format="D MMM YYYY"
                                                 withTitle>{financePaymentTrack.paymentProgressStatusDate}</Moment>
                                    </div>
                                </Col>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div>{!_.isNull(financePaymentTrack.paidAmount) ? (
                                        <span>{financePaymentTrack.paidAmount.toLocaleString('en-IN')}</span>) : (
                                        <span></span>)}</div>
                                </Col>
                                <Col xs={6} sm={3} className="pt-3 pb-3">
                                    <div>{!_.isEmpty(financePaymentTrack.comment) ? (
                                        <span>{financePaymentTrack.comment}</span>) : (<span>-</span>)}</div>
                                </Col>
                                {!_.isEmpty(financePaymentTrackList) && financePaymentTrackList.length - 1 !== swtIndex &&
                                    <hr style={{marginBottom: '0'}}/>}
                            </Row>))}
                    </Card>
                </div>) : (<div></div>)}
            </Col>
        </Row>


        {/* ============================================  PM  Accept modal ============================================= */}

        <Modal isOpen={pMAcceptInvoiceModal}
               toggle={togglePMAcceptInvoiceModal}
               backdrop="static"
        >
            <ModalHeader
                toggle={togglePMAcceptInvoiceModal}>{t('integr8.approve.invoice.text')}</ModalHeader>
            <ModalBody>
                <div className="fw-bold"><b>{t('program.manager.view.invoice.send.approval')}{mtMemberName}?</b></div>

                <div className="mb-4">
                    <div className="mt-3 mb-2">{t('program.manager.view.invoice.add.comment')}</div>
                    <Input type="textarea" className="next_input" name="comment" rows="5"
                           onChange={onPmCommentChange}
                           placeholder={t('integr8.add.a.comment.text')}/>
                </div>
                <hr style={{marginLeft: '-16px', marginRight: '-16px'}}/>
                <div className="mt-2 mb-2"><b>{t('integr8.partner.feedback.text')}</b></div>
                <div>
                    <div className="mb-2">
                        <span>{t('integr8.share.your.feedback.text')}<span>{t('integr8.with.ref.their.work')}</span></span>
                    </div>
                </div>
                <div className="text-center">
                    <Rating
                        onClick={onChangeRating}
                        allowFraction={true}
                    />
                </div>
                <div className={'mt-3'}>
                    <Input type="textarea" className="next_input" name="comment" rows="5"
                           onChange={onPmStarRatingCommentChange}
                           placeholder={t('integr8.type.your.feedback.text')}/>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button className="next_cancel_btn me-3"
                        onClick={togglePMAcceptInvoiceModal}>{t('integr8.common.cancel.text')}</Button>

                <ButtonWithLoader className="next_btn_success_integr8"
                                  disabled={disableApprovalBtn()}
                                  onClick={pMAcceptSowInvoice}
                                  loading={isUpLoaded}
                                  text={t('program.manager.view.invoice.approve.send.btn')}
                >
                </ButtonWithLoader>

            </ModalFooter>

        </Modal>


        {/* ===========================================  PM Invoice Reject modal ========================================== */}

        <Modal
            isOpen={pMRejectInvoiceModal}
            toggle={togglePMInvoiceRejectModal}
            // fade={false}
            backdrop="static"
        >
            <ModalHeader
                toggle={togglePMInvoiceRejectModal}>{t('integr8.reject.invoice.text')}</ModalHeader>
            <ModalBody>

                <div className={''}>
                    <div className="fw-bold"><b>{t('program.manager.view.invoice.reject.invoice')}</b></div>
                    <div className="mt-2 mb-2">{t('program.manager.view.invoice.add.comment')}</div>
                    <Input type="textarea" className="next_input" name="comment" rows="5"
                           onChange={onPmCommentChange}
                           placeholder={t('integr8.add.a.comment.text')}/>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn me-3" onClick={togglePMInvoiceRejectModal}>
                    {t('integr8.common.close.text')}
                </Button>
                <ButtonWithLoader className="next_accent2_btn"
                                  onClick={pMRejectSowInvoice}
                                  loading={isUpLoaded}
                                  text={t('program.manager.view.invoice.btn.reject')}
                >
                </ButtonWithLoader>
            </ModalFooter>
        </Modal>


        {/* ============================================  MT  Accept modal ============================================== */}

        <Modal isOpen={mTAcceptInvoiceModal}
               toggle={toggleMTAcceptInvoiceModal}
               backdrop="static"
            // fade={false}
        >
            <ModalHeader
                toggle={toggleMTAcceptInvoiceModal}>{t('integr8.approve.invoice.text')}</ModalHeader>
            <ModalBody>
                <div className="fw-bold">{t('mt.view.invoice.accept.modal.body.accept')}</div>
                <div>
                    <div className="mt-3 mb-2">{t('program.manager.view.invoice.add.comment')}</div>
                    <Input type="textarea" className="next_input mt-2" name="comment" rows="5"
                           onChange={onMtCommentChange}
                           placeholder={t('integr8.add.a.comment.text')}/>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button className="next_cancel_btn me-2"
                        onClick={toggleMTAcceptInvoiceModal}>{t('integr8.common.cancel.text')}</Button>

                <ButtonWithLoader className="next_btn_success_integr8"
                                  onClick={mtAcceptSowInvoice}
                                  loading={isUpLoaded}
                                  text={t('program.manager.view.invoice.approve.btn')}
                ></ButtonWithLoader>

            </ModalFooter>

        </Modal>

        {/* ===============================================  MT  Reject modal ============================================ */}

        <Modal
            isOpen={mTRejectInvoiceModal}
            toggle={toggleMTInvoiceRejectModal}
            backdrop="static"
            // fade={false}
        >
            <ModalHeader
                toggle={toggleMTInvoiceRejectModal}>{t('integr8.reject.invoice.text')}</ModalHeader>
            <ModalBody>
                <div className="fw-bold">{t('mt.view.invoice.accept.modal.body.reject')}</div>
                <div>
                    <div className="mt-2 mb-2">{t('finance.partner.payment.modal.body.comment')}</div>
                    <Input type="textarea" className="next_input mt-2" name="comment" rows="5"
                           onChange={onMtCommentChange}
                           placeholder={t('integr8.mt.partner.payment.modal.body.placeholder')}/>

                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn me-2" onClick={toggleMTInvoiceRejectModal}>
                    {t('integr8.common.close.text')}
                </Button>

                <ButtonWithLoader className="next_accent2_btn"
                                  onClick={mtRejectSowInvoice}
                                  loading={isUpLoaded}
                                  text={t("program.manager.view.invoice.reject.btn")}>

                </ButtonWithLoader>
            </ModalFooter>
        </Modal>


        {/* ======================================  project-details Invoice page modal ===================================== */}

        <Modal
            isOpen={projectDetailsSowModal}
            toggle={toggleProjectDetailsSowModal}
            backdrop="static"
            // className={className}
        >
            <ModalHeader
                toggle={toggleProjectDetailsSowModal}>{t('integr8.sow.project.details.btn')}</ModalHeader>
            <ModalBody className={'px-3'}>

                <div className="integr8_next_small_card">
                    <Row>
                        <Col>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#FAC410', height: '70%'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2">
                                            <img style={{height: '30px'}}
                                                 src={img1}
                                                 alt="total-goal"/>
                                        </div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.estimated.project.value')}</div>
                                            {!_.isEmpty(project.projections) ? (<h6
                                                className="goal_count mt-1 ">₹{_.sumBy(project.projections, 'projectionAmount').toLocaleString('en-IN')}
                                            </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#26C6DA', height: '70%', marginLeft: '-20px'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                                     src={img2}
                                                                                     alt="ontrack-goal"/></div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.projected.cost.text')}</div>
                                            {!_.isEmpty(project.projectServiceCosts) ? (<h6
                                                className="goal_count mt-1 ">₹{_.sumBy(project.projectServiceCosts, 'totalCost').toLocaleString('en-IN')}
                                            </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>


                    <Row style={{marginTop: '-27px'}}>
                        <Col>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#8961FF', height: '70%'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                                     src={img3}
                                                                                     alt="delayed-goal"/></div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.amount.invoiced.text')}</div>
                                            {!_.isEmpty(project.projectInvoices) ? (<h6
                                                className="goal_count mt-1 ">₹{_.sumBy(project.projectInvoices, 'invoiceAmount').toLocaleString('en-IN')}
                                            </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="card next_radious shadow-none text-white"
                                 style={{backgroundColor: '#4AC18E', height: '70%', marginLeft: '-20px'}}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                                     src={img4}
                                                                                     alt="close-goal"/></div>
                                        <div className="align-self-center ps-3">
                                            <div
                                                className="mb-0 integr8_small_card_text">{t('integr8.paid.by.client.text')}</div>
                                            {!_.isEmpty(project.projectInvoices) ? (<h6
                                                className="goal_count mt-1">₹{_.sumBy(project.projectInvoices, (invoice) => invoice.paymentStatus === 'Y' ? invoice.invoiceAmount : 0).toLocaleString('en-IN')}</h6>) : (
                                                <h6 className="goal_count mt-1">₹0</h6>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>

                <div>
                    <a
                        href={'/integr8/management/individual-project/' + project.projectId_ENCRYPTED + '#1'}
                        target="_blank">{t('pm.project.details.modal.click.here')}</a>&nbsp;
                    <span>{t("pm.project.details.modal.text")}</span>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button className="next_cancel_btn me-3"
                        onClick={toggleProjectDetailsSowModal}>
                    {t("integr8.close.btn.text")}
                </Button>
            </ModalFooter>
        </Modal>

        {/*==============================================Print Page========================================================*/}

        <div className={'print-content'} style={{ display: 'none' }}>

            <div className={'p-5'} ref={componentRef}>
                <div style={{ width: '1000px', margin: '0 auto', zoom: '1' }}>
                    <Row className="mb-3 mt-2">
                        <div style={{ width: '50%' }}>
                            <h4 className="d-inline-block">
                                <b>{t('program.manager.view.invoice.number.invoice')} {invoiceNumber}</b>
                            </h4>
                        </div>

                        <div style={{ width: '50%' }}>
                            <div className="text-md-end text-center mt-3 mt-md-0">

                            </div>
                        </div>
                    </Row>


                    <Row>
                        <div style={{ width: '50%' }}>
                            <div className="pb-2"><b>{t('program.manager.view.invoice.header.think.talent')}</b>
                            </div>
                            <Card className="border next_radious mt-1">
                                <div>
                                    <Row className="invoice_body">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                            <div>{t('mt.view.invoice.address')}</div>
                                        </div>
                                        <div style={{ width: '75%' }} className="pt-3 pb-3">
                                            <div>{t('mt.view.invoice.address.details')}</div>
                                        </div>
                                    </Row>
                                    <Row className="invoice_body">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                            <div>{t('mt.view.invoice.email')}</div>
                                        </div>
                                        <div style={{ width: '75%' }} className="pt-3 pb-3">
                                            <div>{t('mt.view.invoice.email.id')}</div>
                                        </div>
                                    </Row>
                                    <Row className="invoice_body">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                            <div>{t('mt.view.invoice.phone')}</div>
                                        </div>
                                        <div style={{ width: '75%' }} className="pt-3 pb-3">
                                            <div>{t('mt.view.invoice.phone.number')}</div>
                                        </div>
                                    </Row>
                                    <Row className="invoice_body">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                            <div>{t('mt.view.invoice.gstin')}</div>
                                        </div>
                                        <div style={{ width: '75%' }} className="pt-3 pb-3">
                                            <div>{t('mt.view.invoice.gstin.number')}</div>
                                        </div>
                                    </Row>
                                    <Row className="invoice_body">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                            <div>{t('mt.view.invoice.state')}</div>
                                        </div>
                                        <div style={{ width: '75%' }} className="pt-3 pb-3">
                                            <div>{t('mt.view.invoice.state.text')}</div>
                                        </div>
                                    </Row>
                                    <Row className="issue_SOW_body">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                            <div>{t('mt.view.invoice.cin')}</div>
                                        </div>
                                        <div style={{ width: '75%' }} className="pt-3 pb-3">
                                            <div>{t('mt.view.invoice.cin.number')}</div>
                                        </div>
                                    </Row>
                                </div>
                            </Card>
                        </div>

                        <div style={{ width: '50%' }}>
                            <div className="pb-2">
                                <b>{t('program.manager.view.invoice.title.partner.details')}</b></div>
                            <Card className="border next_radious mt-1">
                                <Row className="invoice_body">
                                    <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('mt.view.invoice.partner.name')}</div>
                                    </div>
                                    <div style={{ width: '75%' }} className="pt-3 pb-3">
                                        <div>{partnerName}</div>
                                    </div>
                                </Row>
                                <Row className="invoice_body">
                                    <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('mt.view.invoice.partner.address')}</div>
                                    </div>
                                    {!_.isEmpty(userBilling) ? (
                                      <div style={{ width: '75%' }} className="pt-3 pb-3">
                                          <div>{userBilling.billingAddress}</div>
                                      </div>
                                    ) : (
                                      <div style={{ width: '75%' }} className="pt-3 pb-3">
                                          <div>-</div>
                                      </div>
                                    )}
                                </Row>
                                <Row className="invoice_body">
                                    <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('mt.view.invoice.project.code')}</div>
                                    </div>
                                    <div style={{ width: '75%' }} className="pt-3 pb-3">
                                        <div>{projectCode}</div>
                                    </div>
                                </Row>
                                <Row className="invoice_body">
                                    <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('mt.view.invoice.sow.number')}</div>
                                    </div>
                                    <div style={{ width: '75%' }} className="pt-3 pb-3">
                                        <div>{sowNumber}</div>
                                    </div>
                                </Row>
                                <Row className="invoice_body">
                                    <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div className="mt-2">{t('mt.view.invoice.gst.applicable')}</div>
                                    </div>
                                    <div style={{ width: '15%' }} className="pt-3 pb-3 invoice_body_border">
                                        {!_.isNull(sowInvoice.gstStatus) ? (
                                          <div className="mt-2">{sowInvoice.gstStatus}</div>
                                        ) : (
                                          <div className="mt-2">No</div>
                                        )}
                                    </div>
                                    {sowInvoice.gstStatus === 'Yes' && (
                                      <div style={{ width: '60%' }} className="pt-3 pb-3 invoice_body_border">
                                          <div
                                            className={'mt-2'}>{_.isEmpty(userBilling) ? '-' : userBilling.gstNo}</div>
                                      </div>
                                    )}
                                    {(sowInvoice.gstStatus === 'No' || _.isNull(sowInvoice.gstStatus)) && (
                                      <div style={{ width: '60%' }} className="pt-3 pb-3 invoice_body_border">
                                          <div className={'mt-2'}>-</div>
                                      </div>
                                    )}
                                </Row>
                                <Row className="issue_SOW_body">
                                    <div style={{ width: '25%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('mt.view.invoice.partner.ref.number')}</div>
                                    </div>
                                    {!_.isEmpty(sowInvoice.partnerRefNumber) ? (
                                      <div style={{ width: '75%' }} className="pt-3 pb-3">
                                          <div>{sowInvoice.partnerRefNumber}</div>
                                      </div>
                                    ) : (
                                      <div style={{ width: '75%' }} className="pt-3 pb-3">
                                          <div>-</div>
                                      </div>
                                    )}
                                </Row>
                            </Card>
                        </div>
                    </Row>

                    <Row>
                        <Col>
                            <Card className="border next_radious mt-1"
                                  style={{ width: '100%', margin: '0 auto' }}>
                                <Row className="issue_SOW_heading" style={{ width: '100%' }}>
                                    <div style={{ width: '20.6%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('integr8.view.invoice.description')}</div>
                                    </div>
                                    <div style={{ width: '12.6%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div>{t('integr8.invoice.hsn.sac')}</div>
                                    </div>
                                    <div style={{ width: '16.6%' }}
                                         className="pt-3 pb-3 invoice_body_border text-center">
                                        <div>{t('integr8.view.invoice.unit.assigned')}</div>
                                    </div>
                                    <div style={{ width: '16.6%' }}
                                         className="pt-3 pb-3 invoice_body_border text-center">
                                        <div>{t('integr8.view.invoice.fee')}</div>
                                    </div>
                                    <div style={{ width: '16.6%' }}
                                         className="pt-3 pb-3 invoice_body_border text-center">
                                        <div>{t('integr8.view.invoice.units')}</div>
                                    </div>
                                    <div style={{ width: '16.6%' }} className="pt-3 pb-3 text-center">
                                        <div>{t('integr8.view.invoice.amount')}</div>
                                    </div>
                                </Row>

                                {!_.isEmpty(sowInvoice.sowInvoiceDescriptions) && sowInvoice.sowInvoiceDescriptions.map((sowInvoiceDescription, sowInvoiceDescriptionIndex) => (
                                  <Row className="issue_SOW_body" key={sowInvoiceDescriptionIndex}
                                       style={{ width: '100%' }}>
                                      <div style={{ width: '20.6%' }} className="pt-3 pb-3 invoice_body_border">
                                          <div>{sowInvoiceDescription.sowDescription.description}</div>
                                      </div>
                                      <div style={{ width: '12.6%' }} className="pt-3 pb-3 invoice_body_border">
                                          <div>{hsn}</div>
                                      </div>
                                      <div style={{ width: '16.6%' }}
                                           className="pt-3 pb-3 invoice_body_border text-center">
                                          <div>{sowInvoiceDescription.sowDescription.unit}</div>
                                      </div>
                                      <div style={{ width: '16.6%' }}
                                           className="pt-3 pb-3 invoice_body_border text-center">
                                          <div>{sowInvoiceDescription.sowDescription.fee.toLocaleString('en-IN')}</div>
                                      </div>
                                      <div style={{ width: '16.6%' }}
                                           className="pt-3 pb-3 invoice_body_border text-center">
                                          <div>{sowInvoiceDescription.unit}</div>
                                      </div>
                                      <div style={{ width: '16.6%' }} className="pt-3 pb-3 text-center">
                                          <div>{sowInvoiceDescription.amount.toLocaleString('en-IN')}</div>
                                      </div>
                                      <hr style={{ margin: '0px', width: '100%' }} />
                                  </Row>
                                ))}

                                <Row className="issue_SOW_body" style={{ width: '100%' }}>
                                    <div style={{ width: '83%' }} className="pt-3 pb-3 invoice_body_border">
                                        <div className={'text-end'}
                                             style={{ paddingRight: '5%' }}>{t('partner.ptnr.invoice.desc.sub.total')}</div>
                                    </div>
                                    <Col style={{ width: '17%' }} className="pt-3 pb-3 text-center">
                                        <div>{sowInvoice?.subTotalAmt?.toLocaleString('en-IN') || 0}</div>
                                    </Col>
                                    <hr style={{ margin: '0px', width: '100%' }} />
                                </Row>

                                {sowInvoice?.gstStatus === 'Yes' && (
                                  <div style={{ width: '100%' }}>
                                      <Row className="issue_SOW_body">
                                          <div style={{ width: '83%' }}
                                               className="pt-3 pb-3 invoice_body_border">
                                              <div className={'text-end'}
                                                   style={{ paddingRight: '5%' }}>{t('partner.ptnr.invoice.desc.sub.gst')}</div>
                                          </div>
                                          <div style={{ width: '17%' }} className="pt-3 pb-3 text-center">
                                              <div>{sowInvoice?.gstAmt?.toLocaleString('en-IN') || 0}</div>
                                          </div>
                                          <hr style={{ margin: '0px', width: '100%' }} />
                                      </Row>
                                      <Row className="issue_SOW_body">
                                          <div style={{ width: '83%' }}
                                               className="pt-3 pb-3 invoice_body_border">
                                              <div className={'text-end'}
                                                   style={{ paddingRight: '5%' }}>{t('partner.ptnr.invoice.desc.total.amount')}</div>
                                          </div>
                                          <div style={{ width: '17%' }} className="pt-3 pb-3 text-center">
                                              <div>{sowInvoice?.totalAmt?.toLocaleString('en-IN') || 0}</div>
                                          </div>
                                      </Row>
                                  </div>
                                )}

                                {(sowInvoice?.gstStatus === 'No' || sowInvoice?.gstStatus === null) && (
                                  <Row className="issue_SOW_body" style={{ width: '100%' }}>
                                      <div style={{ width: '83%' }} className="pt-3 pb-3 invoice_body_border">
                                          <div className={'text-end'}
                                               style={{ paddingRight: '5%' }}>{t('partner.ptnr.invoice.desc.total.amount')}</div>
                                      </div>
                                      <div style={{ width: '17%' }} className="pt-3 pb-3 text-center">
                                          <div>{sowInvoice?.subTotalAmt?.toLocaleString('en-IN') || 0}</div>
                                      </div>
                                  </Row>
                                )}
                            </Card>
                        </Col>
                    </Row>

                    <small>{t('program.manager.view.invoice.note.tds.deduction')}</small>
                    <Row className="mt-4">
                        <div>
                            {!_.isEmpty(invoiceDocumentTrackList) ? (<div>
                                <div className="pb-2"><b>{t('program.manager.view.invoice.document.track')}</b>
                                </div>
                                <Card className={'border next_radious mt-1'}>
                                    <Row className="issue_SOW_heading">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('program.manager.view.invoice.status')}</div>
                                        </div>
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('pm.sow.table.th.user')}</div>
                                        </div>
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('pm.sow.table.th.date')}</div>
                                        </div>
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('program.manager.view.invoice.comments')}</div>
                                        </div>
                                    </Row>

                                    {invoiceDocumentTrackList.map((invoiceDocumentTrack, swtIndex) => (
                                      <Row className="issue_SOW_body" key={swtIndex}>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div>
                                                  {invoiceDocumentTrack.invoiceStatus === 'PIssued' &&
                                                    <span>{t('program.manager.invoice.status.comment.pissued.text')}</span>}
                                                  {invoiceDocumentTrack.invoiceStatus === 'PInitiated' &&
                                                    <span>{t('program.manager.invoice.status.comment.pinitiated.text')}</span>}
                                                  {invoiceDocumentTrack.invoiceStatus === 'PMApproved' &&
                                                    <span>{t('program.manager.invoice.status.comment.pmapproved.text')}</span>}
                                                  {invoiceDocumentTrack.invoiceStatus === 'PMRejected' &&
                                                    <span>{t('program.manager.invoice.status.comment.pmrejected.text')}</span>}
                                                  {invoiceDocumentTrack.invoiceStatus === 'MTApproved' &&
                                                    <span>{t('program.manager.invoice.status.comment.mtapproved.text')}</span>}
                                                  {invoiceDocumentTrack.invoiceStatus === 'MTRejected' &&
                                                    <span>{t('program.manager.invoice.status.comment.mtrejected.text')}</span>}
                                              </div>
                                          </div>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div>{!_.isEmpty(invoiceDocumentTrack.projectAdmin.userInfo) ? invoiceDocumentTrack.projectAdmin.userInfo.name : ''}</div>
                                          </div>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div><Moment local titleFormat="D MMM YYYY" format="D MMM YYYY"
                                                           withTitle>{invoiceDocumentTrack.timestamp}</Moment>
                                              </div>
                                          </div>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div>{!_.isEmpty(invoiceDocumentTrack.comment) ? (
                                                <span>{invoiceDocumentTrack.comment}</span>) : (
                                                <span>-</span>)}</div>
                                          </div>
                                          {!_.isEmpty(invoiceDocumentTrackList) && invoiceDocumentTrackList.length - 1 !== swtIndex &&
                                            <hr style={{ marginBottom: '0' }} />}
                                      </Row>))}
                                </Card>
                            </div>) : (<div></div>)}
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <div>
                            {!_.isEmpty(financePaymentTrackList) ? (<div>
                                <div className="pb-2"><b>{t('program.manager.view.invoice.payment.track')}</b>
                                </div>
                                <Card className={'border next_radious mt-1'}>
                                    <Row className="issue_SOW_heading">
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('program.manager.view.invoice.status')}</div>
                                        </div>
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('pm.sow.table.th.date')}</div>
                                        </div>
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('program.manager.project.actual.rev.amount')}</div>
                                        </div>
                                        <div style={{ width: '25%' }} className="pt-3 pb-3">
                                            <div>{t('program.manager.view.invoice.comments')}</div>
                                        </div>
                                    </Row>

                                    {financePaymentTrackList.map((financePaymentTrack, swtIndex) => (
                                      <Row className="issue_SOW_body" key={swtIndex}>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div>
                                                  {financePaymentTrack.invoicePaymentProgressStatus === 'FullyPaid' ? (
                                                    <span>{t('integr8.finance.partner.payment.fully.paid.btn')}</span>) : (
                                                    <span>{t('integr8.finance.partner.payment.partially.paid.btn')}</span>)}
                                              </div>
                                          </div>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div><Moment local titleFormat="D MMM YYYY" format="D MMM YYYY"
                                                           withTitle>{financePaymentTrack.paymentProgressStatusDate}</Moment>
                                              </div>
                                          </div>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div>{!_.isNull(financePaymentTrack.paidAmount) ? (
                                                <span>{financePaymentTrack.paidAmount.toLocaleString('en-IN')}</span>) : (
                                                <span></span>)}</div>
                                          </div>
                                          <div style={{ width: '25%' }} className="pt-3 pb-3">
                                              <div>{!_.isEmpty(financePaymentTrack.comment) ? (
                                                <span>{financePaymentTrack.comment}</span>) : (
                                                <span>-</span>)}</div>
                                          </div>
                                          {!_.isEmpty(financePaymentTrackList) && financePaymentTrackList.length - 1 !== swtIndex &&
                                            <hr style={{ marginBottom: '0' }} />}
                                      </Row>))}
                                </Card>
                            </div>) : (<div></div>)}
                        </div>
                    </Row>
                </div>
            </div>
        </div>


    </div>)
}

export default PmMtPartnerFinanceViewInvoice;