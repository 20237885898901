import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ReactNotifications } from 'react-notifications-component';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorUI from './ErrorBoundary';
import { sentryInit, sentryRootOptions } from '@devopsthink/react-custom-component-util';
import conf_prop from './properties/properties';

sentryInit(
  conf_prop.get('sentry_dsn'),
  conf_prop.get('ENV'),
  conf_prop.get('sentry_release'),
);

const errorUi = <ErrorUI />;
const rootElement = document.getElementById('root');
const root = createRoot(rootElement, { ...sentryRootOptions });
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
      suspense: false,
      useErrorBoundary: true,
      throwOnError: true,
    }, mutations: {
      retry: 3, retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
    },
  }, queryCache: new QueryCache({
    onError: (error, query) => {
      console.error(`Query ${JSON.stringify(query.queryKey)} failed:`, error);
    }, onSuccess: (data, query) => {
      console.log(`Query ${JSON.stringify(query.queryKey)} succeeded:`);
    },
  }), mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      console.error(`Mutation ${JSON.stringify(mutation.mutationKey)} failed:`, error);
    }, onSuccess: (data, variables, context, mutation) => {
      console.log(`Mutation ${JSON.stringify(mutation.mutationKey)} succeeded:`);
    },
  }), logger: {
    log: console.log, warn: console.warn, error: console.error,
  },
});

root.render(<Provider store={store}>
    < BrowserRouter>
      <I18nextProvider i18n={i18n}>

        <Sentry.ErrorBoundary fallback={errorUi}>
          <ReactNotifications />
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Sentry.ErrorBoundary>

      </I18nextProvider>
    </BrowserRouter>
  </Provider>,

  // document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
