import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ApiUtils from '../../../api/ApiUtils';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContactController from '../../../api/controller/contact-controller';
import { Box, Drawer } from '@mui/material';
import { projectContactInsertSchema } from '../../../validation/project-contact-validation';
import { Link } from 'react-router-dom';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';


function ClientContactView({project, findProjectById}) {

    let userInfo = ApiUtils.getLocalStorage('userInfo');
    const [isLoaded, set_isLoaded] = useState(false);
    const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
    const [isAddBtnLoading, set_isAddBtnLoading] = useState(false);
    const [isMapBtnLoading, set_isMapBtnLoading] = useState(false);
    const [isUpdateBtnLoaded, set_isUpdateBtnLoaded] = useState(false);
    const [updateProjectModal, set_updateProjectModal] = useState(false);
    const [addClientContactModal, set_addClientContactModal] = useState(false);
    const [isLinkVisible, setIsLinkVisible] = useState(null);
    const [state1, setState1] = useState({right: false});
    const [state2, setState2] = useState({right: false});
    const [state3, setState3] = useState({right: false});
    const {t} = useTranslation();
    const schema = useMemo(() => projectContactInsertSchema, []);
    const [selectEmail, setSelectEmail] = useState(null);
    const [mapValue, setMapValue] = useState({});
    const [emails, set_emails] = useState([]);
    const [clientContacts, set_clientContacts] = useState([]);
    const [contact, setContact] = useState({
        name: null,
        designation: null,
        contactNumber: null,
        email: null,
        homeAddress: null,
        officeAddress: null,
        userInfo: {userId: userInfo.userId},
        projectContactMappers: [{project: project}],
    });
    const [contactUpdate, setContactUpdate] = useState({
        name: null,
        designation: null,
        contactNumber: null,
        email: null,
        homeAddress: null,
        officeAddress: null,
    });
    const [contactMapUpdate, setContactMapUpdate] = useState({
        name: null,
        designation: null,
        contactNumber: null,
        email: null,
        homeAddress: null,
        officeAddress: null,
        userInfo: null,
        projectContactMappers: null,
    });


    const updateProjectToggle = () => set_updateProjectModal((updateProjectModalPrev) => {
        return !updateProjectModalPrev;
    });

    const addClientContactToggle = () => set_addClientContactModal((addClientContactModalPrev) => {
        return !addClientContactModalPrev;
    });

    const toggleDrawerAdd = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState1({...state1, [anchor]: open});
        setIsLinkVisible(false);
    };

    const toggleDrawerMap = (anchor, open) => (event) => {

        const mapValue = clientContacts.find(clientContact => clientContact.email === selectEmail);
        setMapValue(mapValue);
        setContactMapUpdate({
            contactId: mapValue.contactId,
            name: mapValue.name,
            designation: mapValue.designation,
            contactNumber: mapValue.contactNumber,
            email: mapValue.email,
            homeAddress: mapValue.homeAddress,
            officeAddress: mapValue.officeAddress,
            userInfo: {userId: userInfo.userId},
            projectContactMappers: [{project: project}],
        });
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState2({...state2, [anchor]: open});
    };


    const toggleDrawerEdit = (clientContact, anchor, open) => (event) => {

        setContactUpdate(clientContact.contact);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState3({...state3, [anchor]: open});
    };

    const handleCancletoggle = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState3(!open);
    };

    function editClientDetail(contact) {
        setContact(contact);
        updateProjectToggle();
    }

    function validateDisable() {
        schema.validate(contact).catch(err => console.warn(err));
        return !schema.isValidSync(contact);
    }


    function addClientContactDetails() {
        setContact({name: null, designation: null, contactNumber: null, email: null, maillingAddress: null});
        addClientContactToggle();
    }

    function onClientDetailsChange(e) {
        setContact({...contact, [e.target.name]: e.target.value});
    }

    function onEmailDetailsChange(e) {
        setContact({...contact, [e.target.name]: e.target.value});
        setSelectEmail(e.target.value);
        if (emails.map(email => email.toLowerCase().toUpperCase()).includes(e.target.value.toLowerCase().toUpperCase())) {
            setIsLinkVisible(true);
        } else {
            setIsLinkVisible(false);
        }
    }


    function onClientDetailsUpdate(e) {
        setContactUpdate({...contactUpdate, [e.target.name]: e.target.value});
    }

    function onClientDetailUpdate() {
        set_isUpdateBtnLoading(true);
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(contact.email)) {
            if (!_.isEmpty(contact.contactNumber) && !_.isEmpty(contact.designation) && !_.isEmpty(contact.name) && !_.isEmpty(contact.maillingAddress)) {

                ContactController.updateContact(contact).then(result => {
                    toastify('success', t('common.component.proj.clnt.cont.update.successfully'), t('common.component.proj.clnt.cont.update.successfully'));


                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    updateProjectToggle();
                    findProjectById();
                    set_isUpdateBtnLoading(false);
                });
            }

        } else {
            toastify('error', t('common.component.proj.clnt.cont.invalid.email'), t('common.component.proj.clnt.cont.invalid.email'));
        }
    }

    function insertContact() {
        const matchEmail = !_.isEmpty(contact.email) && emails.includes(contact.email);

        if (matchEmail) {
            toastify('error', t('pm.add.contact.drawer.email.error'), t('pm.add.contact.drawer.email.error'));
        } else {
            set_isAddBtnLoading(true);
            ContactController.insertProjectContact(contact).then(result => {
                toastify('success', t('common.component.proj.clnt.cont.added.successfully'), t('common.component.proj.clnt.cont.added.successfully'));
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                set_isAddBtnLoading(false);
                setState1(!open);
                findProjectById();
                findAllContact();
            });
        }
    }

    function updateContactDetails() {

        if (!_.isEmpty(contactUpdate.name) && !_.isEmpty(contactUpdate.designation) && !_.isEmpty(contactUpdate.email)) {

            set_isUpdateBtnLoaded(true);
            contactUpdate.userInfo = { userId: userInfo.userId };
            ContactController.updateContact(contactUpdate).then(result => {
                toastify('success', t('pm.mt.sales.add.contacts.updated'), t('pm.mt.sales.add.contacts.updated'));
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                set_isUpdateBtnLoaded(false);
                // setState2(!open);
                setState3(!open);
                findProjectById();
            });

        } else {
            toastify('error', t('common.toastify.message.some.fields.are.blank'), t('common.toastify.message.some.fields.are.blank'));
        }
    }

    function onClientDetailsMap(e) {
        setContactMapUpdate({...contactMapUpdate, [e.target.name]: e.target.value});
    }



    function updateMapContactDetails() {
        set_isMapBtnLoading(true);

        // contactMapUpdate.userInfo = {userId: userInfo.userId};
        // contactMapUpdate.projectContactMappers = [{project: project}];
        ContactController.updateMapContact(contactMapUpdate).then(result => {
            toastify('success', t('pm.mt.sales.add.contacts.mapped.successfully'), t('pm.mt.sales.add.contacts.mapped.successfully'));
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            set_isMapBtnLoading(false);
            setState1(!open);
            setState2(!open);
            findProjectById();
        });
    }

    function findAllContact() {
        ContactController.findAll().then(results => {
            const contacts = results.map(contact => {
                return {
                    ...contact,
                    label: contact.name,
                    value: contact.clientId,

                };

            });
            set_clientContacts(contacts);
            const emails = contacts.map((clientContact) => clientContact.email);
            set_emails(emails);
        }).catch(error => console.error(error));
    }

    useEffect(() => {
        function fetchData() {
            findAllContact();

        }

        fetchData();

    }, []);

    {/*====================================== Add Client Contact Drawer =====================================*/
    }

    const addList = (anchor, row) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins'}}
            role="presentation"

        >
            <Row>
                <Col md={6}>
                    <div className={'d-flex ms-4 mt-4'}>
                        <i className="fa-solid fa-xmark mt-1" style={{color: 'gray', cursor: 'pointer'}}
                           onClick={toggleDrawerAdd(anchor, false)}></i>
                        <div className={'ms-3'} style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#646464',
                        }}>{t('manage.contact.pm.add.contact.header.text')}
                        </div>
                    </div>

                </Col>
                <Col md={6}>
                    <div className={'text-end mt-3 me-3'}>
                        <Button className="next_cancel_btn "
                                onClick={toggleDrawerAdd(anchor, false)}
                        >
                            {t('pm.project.cancel.btn')}
                        </Button>
                        <ButtonWithLoader
                            className=" next_btn ms-2"
                            disabled={(validateDisable() || isAddBtnLoading === true)}
                            loading={isAddBtnLoading}
                            onClick={insertContact}
                            text={t('pm.project.add.btn')}
                        >
                        </ButtonWithLoader>
                    </div>
                </Col>
            </Row>

            <hr/>

            <div className="container font-13">
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1">{t('manage.contact.pm.contact.name.text')}</label></Col>
                        <Col md={9}>
                            <Input type="text" name="name"
                                   onChange={onClientDetailsChange}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>


                    </Row>

                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.email.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="email"
                                   onChange={onEmailDetailsChange}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>

                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>

                                    {_.isNull(isLinkVisible) ? (<span></span>) : (
                                        <div>
                                            {isLinkVisible ? (
                                                <div className={'ms-2'}><span className="text-danger"
                                                                              style={{fontSize: '12px'}}>{t('pm.add.contact.drawer.email.error')}<br/></span>
                                                    <Link
                                                        to=""
                                                        className="text-danger link-underline-danger"
                                                        onClick={toggleDrawerMap(anchor, true)}
                                                        style={{textDecoration: 'underline'}}
                                                    >
                                                        {t('pm.add.contact.drawer.hyperlink')}
                                                    </Link>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                    <Drawer
                                        anchor={anchor}
                                        open={state2[anchor]}
                                        onClose={toggleDrawerMap(anchor, false)}
                                        BackdropProps={{style: {backgroundColor: 'transparent'}}}
                                        PaperProps={{style: {boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.9)'}}}
                                    >
                                        {mapList(anchor)}
                                    </Drawer>
                                </React.Fragment>))}
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1">{t('manage.contact.pm.contact.designation.text')}</label></Col>
                        <Col md={9}>
                            <Input type="text" name="designation"
                                   onChange={onClientDetailsChange}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>

                    </Row>

                </div>

                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.contactnumber.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="contactNumber" maxLength="10"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   onChange={onClientDetailsChange}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.officeaddress.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="officeAddress"
                                   onChange={onClientDetailsChange}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.homeaddress.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="homeAddress"
                                   onChange={onClientDetailsChange}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>

            </div>

        </Box>);

    /*-----------------------------------------------Map Contact Drawer----------------------------------------------*/

    const mapList = (anchor, row) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins'}}
            role="presentation"

            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <Row>
                <Col md={6}>
                    <div className={'d-flex ms-4 mt-4'}>
                        <i className="fa-solid fa-xmark mt-1" style={{color: 'gray', cursor: 'pointer'}}
                           onClick={toggleDrawerMap(anchor, false)}></i>
                        <div className={'ms-3'} style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#646464',
                        }}>{t('manage.contact.pm.map.contact.header.text')}
                        </div>

                    </div>
                </Col>
                <Col md={6}>
                    <div className={'text-end mt-3 me-3'}>
                        <Button className="next_cancel_btn "
                                onClick={toggleDrawerMap(anchor, false)}
                        >
                            {t('pm.project.cancel.btn')}
                        </Button>
                        <ButtonWithLoader
                            className=" next_btn ms-2"
                            loading={isMapBtnLoading}
                            onClick={updateMapContactDetails}
                            text={t('pm.project.manage.contact.drawer.map.btn')}
                        >
                        </ButtonWithLoader>
                    </div>
                </Col>
            </Row>

            <hr/>

            <div className={'ms-4 me-3 mb-4'}
                 style={{fontSize: '12px', color: '#565656'}}>{t('pm.add.contact.drawer.heading.text')}</div>

            <div className="container font-13">
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1">{t('manage.contact.pm.contact.name.text')}</label></Col>
                        <Col md={9}>
                            <Input type="text" name="name"
                                   onChange={onClientDetailsMap}
                                   defaultValue={_.isEmpty(mapValue) ? '' : mapValue.name}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>


                    </Row>

                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.email.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="email"
                                   onChange={onClientDetailsMap}
                                   defaultValue={_.isEmpty(mapValue) ? '' : mapValue.email}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1">{t('manage.contact.pm.contact.designation.text')}</label></Col>
                        <Col md={9}>
                            <Input type="text" name="designation"
                                   onChange={onClientDetailsMap}
                                   defaultValue={_.isEmpty(mapValue) ? '' : mapValue.designation}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>

                    </Row>

                </div>

                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.contactnumber.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="contactNumber" maxLength="10"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   defaultValue={_.isEmpty(mapValue) ? '' : mapValue.contactNumber}
                                   onChange={onClientDetailsMap}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.officeaddress.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="officeAddress"
                                   onChange={onClientDetailsMap}
                                   defaultValue={_.isEmpty(mapValue) ? '' : mapValue.officeAddress}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.homeaddress.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="homeAddress"
                                   onChange={onClientDetailsMap}
                                   defaultValue={_.isEmpty(mapValue) ? '' : mapValue.homeAddress}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>

            </div>

        </Box>);

    /*---------------------------------------------Edit Contact Drawer----------------------------------------------*/

    const editList = (clientContact, anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins'}}
            role="presentation"

        >
            <Row>
                <Col md={6}>
                    <div className={'d-flex ms-4 mt-4'}>
                        <i className="fa-solid fa-xmark mt-1" style={{color: 'gray', cursor: 'pointer'}}
                           onClick={handleCancletoggle(anchor, true)}
                        ></i>
                        <div className={'ms-3'} style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#646464',
                        }}>{t('manage.contact.pm.edit.contact.header.text')}
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={'text-end mt-3 me-3'}>
                        <Button className="next_cancel_btn "
                                onClick={handleCancletoggle(anchor, true)}
                        >
                            {t('pm.project.cancel.btn')}
                        </Button>
                        <ButtonWithLoader
                            className=" next_btn ms-2"
                            loading={isUpdateBtnLoaded}
                            onClick={updateContactDetails}
                            text={t('pm.project.manage.contact.drawer.update.btn')}
                        >
                        </ButtonWithLoader>
                    </div>
                </Col>
            </Row>

            <hr/>

            <div className="container font-13">
                <div className="mt-2">

                    <Row>
                        <Col md={3}><label
                            className="mt-1">{t('manage.contact.pm.contact.name.text')}</label></Col>
                        <Col md={9}>
                            <Input type="text" name="name"
                                   onChange={onClientDetailsUpdate}
                                   defaultValue={_.isEmpty(contactUpdate) ? '' : contactUpdate.name}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>


                    </Row>

                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.email.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="email"
                                   onChange={onClientDetailsUpdate}
                                   defaultValue={_.isEmpty(contactUpdate) ? '' : contactUpdate.email}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1">{t('manage.contact.pm.contact.designation.text')}</label></Col>
                        <Col md={9}>
                            <Input type="text" name="designation"
                                   onChange={onClientDetailsUpdate}
                                   defaultValue={_.isEmpty(contactUpdate) ? '' : contactUpdate.designation}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>

                    </Row>

                </div>

                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.contactnumber.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="contactNumber" maxLength="10"
                                   onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   onChange={onClientDetailsUpdate}
                                   defaultValue={_.isEmpty(contactUpdate) ? '' : contactUpdate.contactNumber}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.officeaddress.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="officeAddress"
                                   onChange={onClientDetailsUpdate}
                                   defaultValue={_.isEmpty(contactUpdate) ? '' : contactUpdate.officeAddress}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2">
                    <Row>
                        <Col md={3}><label
                            className="mt-1"><label>{t('manage.contact.pm.contact.homeaddress.text')}</label></label></Col>
                        <Col md={9}>
                            <Input type="text" name="homeAddress"
                                   onChange={onClientDetailsUpdate}
                                   defaultValue={_.isEmpty(contactUpdate) ? '' : contactUpdate.homeAddress}
                                   placeholder={t('pm.manage.contact.placeholder.text')}
                                   className="mb-2 next_input"/>
                        </Col>
                    </Row>
                </div>

            </div>

        </Box>);


    return (<div>

        <Row>
            <Col md={8}>
                <h5>{t('common.component.proj.clnt.cont.header.client.contact')}</h5>
            </Col>

            {userInfo.role.internalName !== 'MT' ? (<Col md={4} className="text-end">
                {project.projectStatus === 'Y' &&
                    <div style={{marginTop: '-2px'}}>
                        {['right'].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <i className="mdi mdi-plus-circle-outline text-info"
                                   onClick={toggleDrawerAdd(anchor, true)}
                                   style={{cursor: 'pointer', fontSize: '18px'}}></i>
                                <Drawer
                                    anchor={anchor}
                                    open={state1[anchor]}
                                    onClose={toggleDrawerAdd(anchor, false)}
                                    BackdropProps={{style: {backgroundColor: 'transparent'}}}
                                    PaperProps={{style: {boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.9)'}}}
                                >
                                    {addList(anchor)}
                                </Drawer>
                            </React.Fragment>))}
                    </div>}
                {project.projectStatus === 'N' && <div></div>}
            </Col>) : <div></div>}
        </Row>

        <div className="" style={{height: '150px'}}>
            <PerfectScrollbar className="">
                {!_.isEmpty(project.projectContactMappers) ? (<div className={'pe-3'}>
                    {project.projectContactMappers.map((clientContact, contactIndex) => <div key={contactIndex}>
                        {userInfo.role.internalName !== 'MT' ? (<Row>
                                <Col md={12}>
                                    <div className={'d-flex'}>
                                        <div className="mt-2">
                                            <i className="fas fa-user me-2" style={{color: 'gray'}}></i>
                                            <span>{!_.isEmpty(clientContact.contact) ? clientContact.contact.name : ''}</span>
                                        </div>

                                        {project.projectStatus === 'N' ? ('') : (
                                            <div className="ms-auto mt-2">
                                                {['right'].map((anchor) => (
                                                    <React.Fragment key={anchor}>
                                                        <i className="ti-marker-alt text-muted  "
                                                           onClick={toggleDrawerEdit(clientContact, anchor, true)}
                                                           style={{cursor: 'pointer'}}></i>
                                                        <Drawer
                                                            anchor={anchor}
                                                            open={state3[anchor]}
                                                            onClose={toggleDrawerEdit(clientContact, anchor, false)}
                                                            BackdropProps={{style: {backgroundColor: 'transparent'}}}
                                                            PaperProps={{style: {boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.9)'}}}
                                                        >
                                                            {editList(anchor, clientContact)}
                                                        </Drawer>
                                                    </React.Fragment>))}

                                            </div>)}
                                    </div>
                                </Col>


                                {project.projectStatus === 'N' && <Col className="text-right">
                                    <div></div>
                                </Col>}

                            </Row>) :
                            (<Row>
                                <Col>
                                    <div className="mt-2"><i className="fas fa-user me-2" style={{color: 'gray'}}></i>
                                        <span>{!_.isEmpty(clientContact.contact) ? clientContact.contact.name : ''}</span>
                                    </div>
                                </Col>
                            </Row>)}


                        <div><i className="fas fa-phone me-2" style={{color: 'gray'}}></i>+91-
                            {!_.isEmpty(clientContact.contact) ? clientContact.contact.contactNumber : ''}
                        </div>

                        <div><i className="fas fa-envelope me-2" style={{color: 'gray'}}></i>
                            {!_.isEmpty(clientContact.contact) ? clientContact.contact.email : ''}</div>
                        <div><i
                            className="fa-solid fa-building me-2"
                            style={{color: 'gray'}}></i> {!_.isEmpty(clientContact.contact) ? clientContact.contact.designation : ''}
                        </div>


                        {project.projectContactMappers.length - 1 !== contactIndex && (
                            <hr/>
                        )}


                    </div>)}
                </div>) : (<span>{t('pm.individual.project.client.contact.no.data.text')}</span>)}

            </PerfectScrollbar>
        </div>
    </div>);

};

export default ClientContactView;