import * as Yup from 'yup';

export const billingValidationSchema = Yup.object().shape({
    billingName: Yup.string().trim().required('Billing name is required'),
    billingAddress: Yup.string().trim().required('Billing address is required'),
    gstNo: Yup.string().trim().required('GST number is required'),
    panNo: Yup.string().trim().required('PAN number is required'),
    tinNo: Yup.string().trim().required('TIN number is required'),

});
