import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties';

var UserInfoController = {


    logIn: function (userinfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/login`, {
            method: "post",

            body: JSON.stringify(userinfo),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    validata_user_email: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/validate-email`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },
    findAllPm: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get('integr8ServiceUrl')}/rest/userinfo/pm/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/id/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findPhotoById: function (userId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/photo/${userId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkResponse).then(res => res.blob());
    },

    /* get_userinfo: function(userId) {
       return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/id/${userId}`, {
         method: "get",

         headers: new Headers({
           Authorization: `Bearer ${access_token}`,

         })
       }).then(ApiUtils.checkStatus);
     },*/

    updateUserInfo: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/update`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateUserInfoName: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/update-name`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    insertUserInfo: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/insert`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    insertUserInfoBulk: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get('integr8ServiceUrl')}/rest/userinfo/insert-bulk`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    findByRole: function (roleId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/role/${roleId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    uploadProfileImage: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/upload-image`, {
            method: "post",

            body: UserInfo,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,


            })
        }).then(ApiUtils.checkStatus);
    },

    changeProfilePassword: function (userinfo) {
        const access_token = ApiUtils.getCookie("accessToken");
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/password/update`, {
            method: "post",
            body: JSON.stringify(userinfo),
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
    userPasswordChange: function (UserInfo) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/change-password`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    validata_user: function (UserInfo) {
        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/userinfo/validate-user`, {
            method: "post",

            body: JSON.stringify(UserInfo),

            headers: new Headers({
                Authorization: `Bearer ${ApiUtils.getCookie("accessToken").access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },
};

export default UserInfoController;
