import React from 'react';
import img from './assets/images/opps.png';

function ErrorUI() {
  return (
    <div className="error-boundary-container">
      <div className="content">
        <img src={img} alt="Error" className="error-img" />
        <h1 className="error-title">Internal server error</h1>
        <h4 className="error-subtitle">
          Please try again later or contact us if the issue persists.
        </h4>
      </div>
    </div>
  );
}

export default ErrorUI;