const properties = new Map();

const config = {

    serviceUrl: `https://${window.location.hostname}`,
    domainUrl: `https://${window.location.hostname}`,
    video_endpoint: "https://media.thinktalentws48.click",
    integr8_cdn_link: 'https://cdn.thinktalentws48.click/nextv3-integr8',
    app_serviceUrl: 'https://app.thinktalentnext.com',
    oldIntegr8Url: 'old-integr8.thinktalentnext.com',
    oldIntegr8ServiceUrl: 'https://old-integr8.thinktalentnext.com',
};


properties.set("domainUrl", config.domainUrl);
properties.set("serviceUrl", config.serviceUrl);
properties.set("integr8_cdn_link", config.integr8_cdn_link);
properties.set("oldIntegr8Url", config.oldIntegr8Url);
properties.set("oldIntegr8ServiceUrl", config.oldIntegr8ServiceUrl);

properties.set("oldIntegr8ServiceUrl", `${config.oldIntegr8ServiceUrl}/integr8-service`);
properties.set("integr8ServiceUrl", `${config.serviceUrl}/integr8-service`);
properties.set("integr8_oauthServiceUrl", `${config.serviceUrl}/oauth-service`);

properties.set("report_endpoint", `${config.serviceUrl}/integr8-report-service`);
properties.set("langServiceUrl", `${config.app_serviceUrl}/lang-service`);

properties.set("displayLang", false);
properties.set("lng", "en");
properties.set("video_endpoint", config.video_endpoint);



properties.set("sentry_dsn", "https://6642d9d155b24abaeb40f4803d1b7cfc@sentry1.thinktalentws48.click/3374");
properties.set("sentry_release", "nextv3-integr8-react-2-2024-11-12");
properties.set("ENV", "test");

properties.set("idleTime", 1 * 60 * 60);
properties.set("idleCountDown", 20 * 60);

properties.set("logo", "logo.png");
export const logo = "https://cdn.thinktalentws48.click/logo/logo-think.png";
export const favIconLogo = "https://cdn.thinktalentws48.click/favicon/favicon-integr8.png";
export default properties;