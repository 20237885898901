import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import UserDemographicController from '../../api/controller/userdemographic-controller';
import SunEditor from 'suneditor-react';
import update from 'immutability-helper';
import _ from 'lodash';
import { AESEncryption } from '@devopsthink/react-security-util';
import ApiUtils from '../../api/ApiUtils';
import { useParams } from 'react-router-dom';
import { basicSunEditorOptions } from '../../config/suneditor-config';
import Select from 'react-select';
import conf_prop from '../../properties/properties';
import IndustryController from '../../api/controller/industry-controller';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';


//convert class to function component
function PartnerPmProfileTab({userInfo}) {

    const {t} = useTranslation();
    const logInUserInfo = ApiUtils.getLocalStorage("userInfo");
    const path_params = useParams();
    const {userId_ENCRYPTED} = path_params;

    const [isUpLoaded, set_isUpLoaded] = useState(false);
    const [userDemographics, set_userDemographics] = useState([]);
    const [languageList, set_languageList] = useState([]);
    const [industryList, set_industryList] = useState([]);
    const [userDemographicAbout, set_userDemographicAbout] = useState({});
    const [userDemographicLinkedin, set_userDemographicLinkedin] = useState({});
    const [userDemographicLocation, set_userDemographicLocation] = useState({});
    const [userDemographicIndustry, set_userDemographicIndustry] = useState({});
    const [userDemographicLanguage, set_userDemographicLanguage] = useState({});
    // const [userdemographicDetails, set_userdemographicDetails] = useState({
    //     userInfo: {'userId': userInfo.userId}, filterName: "", filterValue: ""
    // });

    const [userdemographic, set_userdemographic] = useState({
        userInfo: {'userId': null},
        filterName: "About me & my experience",
        filterValue: ""
    });

    function onPartnerProfileChange(content) {
        set_userdemographic(userdemographic_prev => update(userdemographic_prev, {filterValue: {$set: content}}));
    }

    async function findByUserInfo(userId_ENCRYPTED) {

        await UserDemographicController.findByUserInfo(userId_ENCRYPTED)
            .then(result => {
                set_userDemographics(result);
                set_userDemographicAbout(_.find(result, {filterName: "About me & my experience"}));
                set_userDemographicLinkedin(_.find(result, {filterName: "LinkedIn"}));
                set_userDemographicLocation(_.find(result, {filterName: "Location"}));
                set_userDemographicIndustry(_.find(result, {filterName: "Industry"}));
                set_userDemographicLanguage(_.find(result, {filterName: "Language"}));


            }).catch((error) => {
                console.error(error);
            });
    }

    function updateDemographicFilter() {
        const userId = parseInt(AESEncryption.decrypt(userInfo.userId_ENCRYPTED));
        userdemographic.userInfo.userId = userId;
        set_isUpLoaded(true);
        UserDemographicController.insertUserDemographic(userdemographic).then(result => {
            toastify("success", t("partner.demographic.uploaded"), t("partner.demographic.uploaded"));
            set_userdemographic(userdemographic_prev => update(userdemographic_prev, {filterValue: {$set: ""}}));
            set_isUpLoaded(false);
        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isUpLoaded(false)
        });

    }

    function findIndustryList() {
        IndustryController.findAll().then(results => {

            set_industryList(results.map(industry => {
                industry.label = industry.industryName;
                industry.value = industry.indId;
                return industry;
            }));

        }).catch(error => console.error(error));
    }

    function onChangeSelectIndustry(event) {
        let industryValue = event && event.length > 0 ? event.map(x => x.label).join(",") : null;

        const userdemographicDetails = {
            userInfo: {'userId': userInfo.userId}, filterName: "Industry", filterValue: industryValue
        };

        if (industryValue) {
            UserDemographicController.insertUserDemographicIndustry(userdemographicDetails).then(result => {
                toastify("success", t("partner.demographic.industry.updated"), t("partner.demographic.industry.updated"));
                findByUserInfo(userInfo.userId_ENCRYPTED);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
            });
        } else {
            const deleteUserdemographicDetails = {
                userInfo: {'userId': userInfo.userId},
                filterName: "Industry",
                filterValue: "",
                udId: userDemographicIndustry.udId
            }
            UserDemographicController.deleteUserDemographic(deleteUserdemographicDetails).then(result => {
                toastify("success", t("partner.demographic.industry.updated"), t("partner.demographic.industry.updated"));
                set_userDemographicIndustry({});
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
            });
        }
    }


    function onChangeSelectLanguage(event) {

        let languageValue = event && event.length > 0 ? event.map(x => x.label).join(",") : null;
        const userdemographicDetails = {
            userInfo: {'userId': userInfo.userId}, filterName: "Language", filterValue: languageValue
        };

        if (languageValue) {
            UserDemographicController.insertUserDemographicLanguage(userdemographicDetails).then(result => {
                toastify("success", t("partner.demographic.language.updated"), t("partner.demographic.language.updated"));
                findByUserInfo(userInfo.userId_ENCRYPTED);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
            });
        } else {
            const deleteUserdemographicDetails = {
                userInfo: {'userId': userInfo.userId},
                filterName: "Language",
                filterValue: "",
                udId: userDemographicLanguage.udId
            }
            UserDemographicController.deleteUserDemographic(deleteUserdemographicDetails).then(result => {
                toastify("success", t("partner.demographic.language.updated"), t("partner.demographic.language.updated"));
                set_userDemographicLanguage({});
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
            });
        }
    }

    useEffect(() => {
        async function fetchData() {

            try {
                const cdnLink = conf_prop.get("integr8_cdn_link") + "/integr8-language.json"
                const languageList = await fetch(cdnLink);
                const languageListJson = await languageList.json();
                const languages = languageListJson.map((language) => {
                    return {
                        ...language, label: language.name, value: language.name
                    }
                });
                set_languageList(languages);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }

            if (logInUserInfo.role.internalName === "PARTNER") {
                await findByUserInfo(userInfo.userId_ENCRYPTED);
                await findIndustryList();
            } else {
                await findByUserInfo(userId_ENCRYPTED);
                await findIndustryList();
            }

        }

        fetchData();

    }, [])


    return (<div className={'px-3'}>

        <Row>
            {logInUserInfo.role.internalName === "PARTNER" ? (<Col sm="12">


                <small>{t("common.components.partner.profile.note.share.profile")}</small>
                <Row>
                    <Col md={6}>
                        <div className="my-2 tw-text-sm">
                            <strong>{t("program.manager.view.prtnr.prof.about.experience")}</strong>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="text-end mt-1">
                            <ButtonWithLoader className="next_btn next_btn_lg"
                                              loading={isUpLoaded}
                                              disabled={_.isEmpty(userdemographic.filterValue)}
                                              onClick={updateDemographicFilter}
                                              text={t("integr8.common.save.btn")}>

                            </ButtonWithLoader>

                        </div>

                    </Col>
                </Row>


                <div className="mt-2">
                    <SunEditor
                        placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                        name="pageDesc"
                        setOptions={{
                            mode: 'inline',
                            height: '150px',
                            width: 'auto',
                            resizingBar: false,
                            showPathLabel: false,
                            charCounter: true,
                            //maxCharCount: 500,
                            "buttonList": [basicSunEditorOptions],
                        }}
                        autoFocus={false}
                        hideToolbar={true}
                        setContents={_.isEmpty(userDemographicAbout) ? "" : userDemographicAbout.filterValue}
                        onChange={onPartnerProfileChange}
                        setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                    />
                </div>
                <Row>
                    <Col md={3}>
                        <div className={'me-2 mt-3'}>
                            <strong>{t("partner.view.prtnr.prof.industry")}</strong>
                        </div>
                    </Col>
                    <Col md={5} className={'px-0'}>
                        <div className={'mt-2'}>
                            <Select
                                options={industryList}
                                onChange={onChangeSelectIndustry}
                                classNamePrefix="next_select"
                                isMulti
                                value={!_.isEmpty(userDemographicIndustry)
                                    ? userDemographicIndustry.filterValue.split(',').map(value => ({
                                        label: value,
                                        value: value
                                    })) : null
                                }
                                placeholder={"Select Industry.."}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <div className={'me-2 mt-3'}>
                            <strong>{t("partner.view.prtnr.prof.language")}</strong>
                        </div>
                    </Col>
                    <Col md={5} className="px-0">
                        <div className={'mt-2 '}>
                            <Select
                                options={languageList}
                                onChange={onChangeSelectLanguage}
                                classNamePrefix="next_select"
                                isMulti
                                value={
                                    !_.isEmpty(userDemographicLanguage)
                                        ? userDemographicLanguage.filterValue.split(',').map(value => ({
                                            label: value,
                                            value: value
                                        }))
                                        : null
                                }
                                placeholder={"Select Language.."}
                            />
                        </div>
                    </Col>
                </Row>

            </Col>) : (<Col sm="12">
                <div className="mt-2 tw-text-sm">
                    <strong>{t("program.manager.view.prtnr.prof.about.experience")}</strong>
                </div>
                <br/>

                <div className="comment-section">
                    {(!_.isEmpty(userDemographicAbout) && userDemographicAbout.filterName === "About me & my experience") ?
                        <div className="comment"
                             dangerouslySetInnerHTML={{__html: userDemographicAbout.filterValue}}/> :
                        <div>{t("integr8.common.not.available.text")}</div>}
                </div>
                <div className="mt-2 tw-text-sm">
                    <span className="fw-bold">{t("program.manager.view.prtnr.prof.industry")}</span>
                    {(!_.isEmpty(userDemographicIndustry) && userDemographicIndustry.filterName === "Industry") ?
                        <span>{userDemographicIndustry.filterValue}</span> :
                        <span>{t("integr8.common.not.available.text")}</span>}
                </div>
                <div className="mt-2 tw-text-sm">
                    <span className="fw-bold">{t("program.manager.view.prtnr.prof.language")}</span>
                    {(!_.isEmpty(userDemographicLanguage) && userDemographicLanguage.filterName === "Language") ?
                        <span>{userDemographicLanguage.filterValue}</span> :
                        <span>{t("integr8.common.not.available.text")}</span>}
                </div>
            </Col>)

            }

        </Row>


    </div>)
}

export default PartnerPmProfileTab;
