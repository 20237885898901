import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ClientContactView } from '../../../views/pm-module/individual-project';

import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import ProjectController from '../../../api/controller/project-controller';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SolutionController from '../../../api/controller/solution-controller';
import _ from 'lodash';
import Moment from 'react-moment';
import IndustryController from '../../../api/controller/industry-controller';
import ClientBillingController from '../../../api/controller/clientbilling-controller';
import ToolController from '../../../api/controller/tool-controller';
import ApiUtils from '../../../api/ApiUtils';
import PmMtProjectOverviewTab from './pm-mt-project-overview-tab';
import PmMtCostAnalysisTab from './pm-mt-cost-analysis-tab';
import PmMtManagePartnerTab from './pm-mt-manage-partner-tab';
import PmMtDocumentsNotesTab from './pm-mt-documents-notes-tab';
import PmMtBillingViewTab from '../../../views/pm-module/individual-project/pm-mt-billing-view-tab';
import PmMtPartnerProjectSummaryTab from '../pm-mt-partner-components/pm-mt-partner-project-summary-tab';
import ProjectImageModal from '../../../views/pm-module/individual-project/pm-mt-project-image-upload';
import { useDispatch } from 'react-redux';
import { ToggleMiniSidebar } from '../../../store/customizer/CustomizerSlice';
import moment from 'moment/moment';
import { individualProjectUpdateSchema } from '../../../validation/individual-project-update-validation';
import { IdpEditTextInput } from '../../../components/utils/edittext-inline';
import ProjectStatusStageController from '../../../api/controller/projectstatusstage-controller';
import ProjectAdminController from '../../../api/controller/projectadmin-controller';
import SalesProjectOverviewTab from '../../sales/sales-project-overview-tab';
import UserInfoController from '../../../api/controller/userinfo-controller';
import { EditextProps } from '../../../config/editext-util';
import BillingEntityView from '../../../views/pm-module/individual-project/billing-entity';
import update from 'immutability-helper';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';


function PmMtIndividualProjectView(props, id) {
  let userInfo = ApiUtils.getLocalStorage('userInfo');
  const location = useLocation();
  const { hash } = location;
  const navigate = useNavigate();
  const query_params = new URLSearchParams(location.search);
  const path_params = useParams();
  const dispatch = useDispatch();
  const { projectId_ENCRYPTED } = path_params;
  const [isLoaded, set_isLoaded] = useState(false);
  const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
  const [isStatusUpdateLoading, set_isStatusUpdateLoading] = useState(false);
  const [isConfirmBtnLoading, set_isConfirmBtnLoading] = useState(false);
  const [projectSowInvoiceActionPendingData, set_projectSowInvoiceActionPendingData] = useState({});
  const [projectDetailsModal, setProjectDetailsModal] = useState(false);
  const [closeProjectModal, setCloseProjectModal] = useState(false);
  // const [activeTab, setActiveTab] = useState(1);
  const [activeTab, set_activetab] = useState(_.isEmpty(hash) ? 1 : parseInt(hash.replace('#', '')));
  const { t } = useTranslation();
  const [courseImageUploadModal, setCourseImageUploadModal] = useState(false);
  const [files, setFiles] = useState(null);
  const [objectURL, setObjectURL] = useState(null);
  const [imageLibrary, setImageLibrary] = useState({});
  const [project, setProject] = useState({});
  const [existTools, setExistTools] = useState([]);
  const [existSolution, setExistSolution] = useState({});
  const [projectToolMappers, setProjectToolMappers] = useState([]);
  const [tools, setTools] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [industries, set_industries] = useState([]);
  const [projectImageUpdate, setProjectImageUpdate] = useState({ project: {}, base64Url: null });
  const [billingEntityDetails, set_billingEntityDetails] = useState([]);
  const [totalProjectedRevenueCurrentYear, set_totalProjectedRevenueCurrentYear] = useState(0);
  const [grossMargin, set_grossMargin] = useState(0);
  const [estimatedTotalProjectValue, set_estimatedTotalProjectValue] = useState(0);
  const [totalProjectCost, set_totalProjectCost] = useState(0);
  const [isUpLoaded, set_isUpLoaded] = useState(false);
  const [statusStage, set_statusStage] = useState([]);
  const [tempStatusStage, set_tempStatusStage] = useState([]);
  const [selectValue, set_selectValue] = useState({});
  const [selectSalesValue, set_selectSalesValue] = useState({});
  const [selectPmValue, set_selectPmValue] = useState({});
  const [salesProjectAdmin, set_salesProjectAdmin] = useState({});
  const [projectModal, set_projectModal] = useState(false);
  const [projectSalesMember, set_projectSalesMember] = useState(false);
  const [projectPMMember, set_projectPMMember] = useState(false);
  const [salesMembers, set_salesMembers] = useState([]);
  const [pmMembers, set_pmMembers] = useState([]);
  const [projectNameChangeModal, set_projectNameChangeModal] = useState(false);
  const [projectNameUpdate, set_projectNameUpdate] = useState({});
  const [isProjectNameUpdateLoading, set_isProjectNameUpdateLoading] = useState(false);

  const [projectUpdate, set_projectUpdate] = useState({
    solution: {},
    projectToolMappers: [{
      tool: {},
    }],
    startDate: null,
    endDate: null,
    noOfParticipants: null,

  });
  const [statusStageColor, set_statusStageColor] = useState([
    { label: 'New Deal', value: 1, color: '#26C6DA' }, // Blue
    { label: 'Proposal', value: 2, color: '#8961FF' }, // Orange
    { label: 'Conversations', value: 3, color: '#FFB22B' }, // Yellow
    { label: 'Won', value: 4, color: '#4AC18E' }, // Green
    { label: 'Lost', value: 5, color: '#E93574' }, // Red
    { label: 'In-Delivery', value: 6, color: '#92D050' }, // Light Green
    { label: 'Completed', value: 7, color: '#858585' }]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided, backgroundColor: state.selectProps.value && state.selectProps.value.color,
      //border: 'none', //  removes the border
      boxShadow: 'none', //  removes the blue border on focus
      width: '100%',
    }), singleValue: (provided, { data }) => ({
      ...provided, color: 'white', backgroundColor: data.color,
    }), option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: isFocused ? data.color : isSelected ? data.color : null,
      color: isFocused || isSelected ? 'black' : data.color,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.selectProps.value ? 'white' : 'grey',
    }),
  };


  const schema = useMemo(() => individualProjectUpdateSchema(), []);

  function validateDisable() {
    schema.validate(projectUpdate).catch(err => console.warn(err));
    return !schema.isValidSync(projectUpdate);
  }

  function disablePastDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  function getFormattedDate(date) {
    if (date === undefined) {
      return null;
    } else {
      return moment(date).format('YYYY-MM-DD');
    }
  }

  function findAllUserInfo() {
    UserInfoController.findAll().then(result => {
      const sales = result.filter(x => x.role.internalName === 'SALES');
      const pmList = result.filter(x => x.role.internalName === 'PM');
      const salesMembers = sales.map(x => ({
        ...x, label: x.name, value: x.userId,
      }));
      const pmMembers = pmList.map(x => ({
        ...x, label: x.name, value: x.userId,
      }));
      set_salesMembers(salesMembers);
      set_pmMembers(pmMembers);

    }).catch((error) => {
      console.error(error);
    });
  }

  const cancelProjectDetailsModal = () => {
    set_projectUpdate({
      projectCode: null, noOfParticipants: null, startDate: null, endDate: null, projectToolMappers: [{
        tool: {},
      }], solution: {}, //industry: {}

    });


    setProjectDetailsModal((projectDetailsModalPrev) => {
      return !projectDetailsModalPrev;
    });

  };

  const projectNameChangeToggle = () => set_projectNameChangeModal((projectNameChangeModalPrev) => {
    return !projectNameChangeModalPrev;
  });

  const toggleProjectDetailsModal = () => setProjectDetailsModal((projectDetailsModalPrev) => {
    return !projectDetailsModalPrev;
  });

  const toggleCloseProjectModal = () => setCloseProjectModal((closeProjectModalPrev) => {
    return !closeProjectModalPrev;
  });

  function handleChangeTab(activeTabInput) {
    set_activetab(activeTabInput);
  }

  function toggleCourseImageUploadModal() {
    setCourseImageUploadModal((courseImageUploadModalPrev) => {
      return !courseImageUploadModalPrev;
    });
  }

  function getCropperFile(croppedFile, objectURLInput) {
    setFiles(croppedFile);
    setObjectURL(objectURLInput);
    setImageLibrary({});
  }

  function toggleProjectImageModal() {
    setProjectImageUpdate({
      setToolImageModal: !projectImageUpdate.setToolImageModal,
    });
  }

  function editProjectDetail(project) {

    const existTools = project.projectToolMappers.map(projectToolMapper => {
      const { tool } = projectToolMapper;
      return {
        ...tool, label: tool.toolName, value: tool.toolId,
      };
    });
    const projectStartDate = getFormattedDate(project.startDate);
    const projectEndDate = getFormattedDate(project.endDate);

    set_projectUpdate(projectUpdate_prev => {
      return {
        ...projectUpdate_prev,
        projectCode: project.projectCode,
        noOfParticipants: project.noOfParticipants,
        startDate: projectStartDate,
        endDate: projectEndDate,
      };

    });
    setExistTools(existTools);
    setExistSolution(project.solution);
    toggleProjectDetailsModal();
  }

  function projectModalToggle() {
    set_projectModal((projectModalPrev) => {
      return !projectModalPrev;
    });
  }


  function projectStatusStageUpdateConfirm(event) {

    if (event.stageName === 'Completed' || event.stageName === 'Lost') {
      toggleCloseProjectModal();
      set_selectValue(event);
    } else {
      projectModalToggle();
      set_selectValue(event);
    }
  }

  function projectStatusStageUpdate() {

    if (userInfo.role.internalName === 'PM') {
      const updateProjectStage = {
        projectId: project.projectId, projectAdmin: project.projectAdmin, projectStatusStage: selectValue,
      };

      set_isStatusUpdateLoading(true);
      ProjectController.pmUpdateProjectStage(updateProjectStage).then(result => {
        toastify('success', t('program.manager.individual.project.project.stage.updated'), t('program.manager.individual.project.project.stage.updated'));
        set_isStatusUpdateLoading(false);
      }).catch((error) => {
        console.error(error);

      }).finally(() => {
        set_isStatusUpdateLoading(false);
        findProjectById();
        projectModalToggle();

      });

    } else {
      const updateProjectStage = {
        projectId: project.projectId, projectAdmin: salesProjectAdmin, projectStatusStage: selectValue,
      };

      set_isStatusUpdateLoading(true);
      ProjectController.salesUpdateProjectStage(updateProjectStage).then(result => {
        toastify('success', t('program.manager.individual.project.project.stage.updated'), t('program.manager.individual.project.project.stage.updated'));
        set_isStatusUpdateLoading(false);
      }).catch((error) => {
        console.error(error);

      }).finally(() => {
        set_isStatusUpdateLoading(false);
        findProjectById();
        projectModalToggle();
      });
    }
  }


  function pmMemberToggle() {
    set_projectPMMember((projectPMMemberPrev) => {
      return !projectPMMemberPrev;
    });
  }

  function salesMemberToggle() {
    set_projectSalesMember((projectSalesMemberPrev) => {
      return !projectSalesMemberPrev;
    });
  }

  function onProjectNameChange(event) {
    const name = event.target.value.trim();
    set_projectNameUpdate(update(project, { projectName: { $set: name } }));
  }

  function onProjectDetailsChange(e) {
    const { name, value } = e.target;
    set_projectUpdate({
      ...projectUpdate, [name]: value,
    });
  }

  function onStartDateChange(e) {
    const {name, value} = e.target;
    set_projectUpdate({
      ...projectUpdate, startDate: value,
    });
  }


  function onEndDateChange(e) {
    const { name, value } = e.target;
    set_projectUpdate({
      ...projectUpdate, endDate: value,
    });
  }

  function onProjectSolutionChange(solution) {
    set_projectUpdate({
      ...projectUpdate, solution: solution,
    });
  }

  function onProjectToolChange(selectedTools) {
    setExistTools(selectedTools);
    const projectToolMappers = selectedTools.map(tool => {
      return { tool: tool };
    });
    set_projectUpdate({
      ...projectUpdate, projectToolMappers: projectToolMappers,
    });
  }

  function closeProject() {

    set_isConfirmBtnLoading(true);
    ProjectController.updateProjectStatusComplete(project).then(response => {
      toastify('success', t('program.manager.individual.project.closed.msg'), t('program.manager.individual.project.closed.msg'));
      toggleCloseProjectModal();
      setProject(response);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      set_isConfirmBtnLoading(false);
    });
  }

  function lostProject() {

    set_isConfirmBtnLoading(true);
    ProjectController.updateProjectStatusLost(project).then(response => {
      toastify('success', t('program.manager.individual.project.lost.msg'), t('program.manager.individual.project.lost.msg'));
      toggleCloseProjectModal();
      setProject(response);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      set_isConfirmBtnLoading(false);
    });
  }

  function onProjectNameUpdate() {

    set_isProjectNameUpdateLoading(true);
    ProjectController.updateProjectName(projectNameUpdate).then(result => {
      toastify('success', t('program.manager.individual.project.project.name.updated'), t('program.manager.individual.project.project.name.updated'));
      set_isUpLoaded(false);
    }).catch((error) => {
      console.error(error);

    }).finally(() => {
      set_isProjectNameUpdateLoading(false);
      findProjectById();
      projectNameChangeToggle();
    });

  }

  function onChangeProjectValue(event) {
    const finalValue = event.replace(/\D/g, '');
    const updateValue = {
      projectId: project.projectId, projectValue: finalValue,
    };

    set_isUpLoaded(true);
    ProjectController.updateProjectValue(updateValue).then(result => {
      toastify('success', t('program.manager.individual.project.project.value.updated'), t('program.manager.individual.project.project.value.updated'));
      set_isUpLoaded(false);
    }).catch((error) => {
      console.error(error);

    }).finally(() => {
      set_isUpLoaded(false);
      findProjectById();
    });

  }

  function updateProjectDetails() {

    set_isUpdateBtnLoading(true);
    const projectUpdateRequest = {
      ...projectUpdate, projectId: project.projectId,
    };
    if (projectUpdateRequest.startDate < projectUpdateRequest.endDate) {

      ProjectController.updateProject(projectUpdateRequest).then(response => {
        toastify('success', t('program.manager.individual.project.update.msg'), t('program.manager.individual.project.update.msg'));
        setProject(...project, response);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        set_isUpdateBtnLoading(false);
        toggleProjectDetailsModal();
        findProjectById();
      });
    } else {
      toastify('error', t('program.manager.individual.project.date.validation.msg'), t('program.manager.individual.project.date.validation.msg'));
      set_isUpdateBtnLoading(false);
    }
  }

  function onChangeSalesMemberConfirm(event) {
    salesMemberToggle();
    set_selectSalesValue(event);
  }


  function salesMemberChange() {
    const updateSalesMember = {
      projectId: project.projectId, userInfo: selectSalesValue,
    };


    set_isStatusUpdateLoading(true);
    ProjectController.pmUpdateSalesMember(updateSalesMember).then(result => {
      toastify('success', t('project.member.updated.successfully'), t('project.member.updated.successfully'));
      set_isUpLoaded(false);
    }).catch((error) => {
      console.error(error);

    }).finally(() => {
      set_isStatusUpdateLoading(false);
      findProjectById();
      salesMemberToggle();
    });
  }

  function onChangePmMemberConfirm(event) {
    pmMemberToggle();
    set_selectPmValue(event);
  }

  function pmMemberChange() {

    const updatePmMember = {
      projectId: project.projectId, projectAdmin: { userInfo: selectPmValue },
    };

    set_isStatusUpdateLoading(true);
    ProjectController.salesUpdatePmMember(updatePmMember).then(result => {
      toastify('success', t('project.member.updated.successfully'), t('project.member.updated.successfully'));
      set_isUpLoaded(false);
    }).catch((error) => {
      console.error(error);

    }).finally(() => {
      set_isStatusUpdateLoading(false);
      findProjectById();
      pmMemberToggle();
    });
  }


  async function findByProjectAndUserInfo() {
    await ProjectAdminController.findByProjectAndUserInfo(projectId_ENCRYPTED, userInfo.userId_ENCRYPTED).then(result => {
      set_salesProjectAdmin(result);
    }).catch((error) => {
      console.error(error);
    });
  }

  async function findProjectById() {

    await ProjectController.findById(projectId_ENCRYPTED)
      .then(project => {
        const projectToolMappers = project.projectToolMappers;
        setProject(project);
        set_projectUpdate({
          projectCode: project.projectCode,
          noOfParticipants: project.noOfParticipants,
          startDate: project.startDate,
          endDate: project.endDate,
          projectToolMappers: projectToolMappers,
          solution: project.solution,
        });

        setProjectToolMappers(projectToolMappers);
        totalProjectedRevenueCurrentYearCalculation(project);
        estimatedProjectValueAndProjectCostAndGrossCalculation(project);
      }).catch((error) => {
        console.error(error);
      });
  }

  async function findProjectStatusStage() {
    await ProjectStatusStageController.findAll().then(statusList => {
      set_statusStage(statusList.map(status => {
        status.label = status.stageName;
        status.value = status.pssId;
        return status;
      }));
      set_tempStatusStage(statusList.filter(status => status.stageName === 'Completed'));
    }).catch(error => console.error(error));
  }

  async function estimatedProjectValueAndProjectCostAndGrossCalculation(project) {

    const estimatedTotalProjectValue = project.projections.reduce((sum, projection) => sum + projection.projectionAmount, 0);
    const totalProjectCost = project.projectServiceCosts.reduce((sum, cost) => sum + cost.totalCost, 0);
    const result = estimatedTotalProjectValue - totalProjectCost;
    const gross = (result / estimatedTotalProjectValue) * 100;
    const finalGross = gross.toFixed(0);

    set_estimatedTotalProjectValue(estimatedTotalProjectValue);
    set_totalProjectCost(totalProjectCost);
    set_grossMargin(finalGross);
  }

  async function totalProjectedRevenueCurrentYearCalculation(project) {

    if (!_.isEmpty(project.projections)) {
      const currentTime = new Date();
      const currentYear = currentTime.getFullYear();
      const currentYearProjectionAmount = project.projections.filter(ppj => ppj.projectionYear === currentYear);
      const currentYearProjectionAmountList = currentYearProjectionAmount.map(ppj => ppj.projectionAmount);
      const totalProjectedRevenueCurrentYear = currentYearProjectionAmountList.reduce((sum, amount) => sum + amount, 0);
      set_totalProjectedRevenueCurrentYear(totalProjectedRevenueCurrentYear);

    } else {
      set_totalProjectedRevenueCurrentYear(0);
    }

  }


  async function findSolutionList() {
    await SolutionController.findAll().then(result => {

      const solutions = result.map((solution) => {
        return {
          ...solution, label: solution.solution, value: solution.psId,
        };
      });
      setSolutions(solutions);

    }).catch((error) => {
      console.error(error);
    });
  }

  async function findIndustryList() {

    await IndustryController.findAll().then(result => {
      const industryList = result.map((industry) => {
        return {
          ...industry, label: industry.industryName, value: industry.indId,
        };
      });

      set_industries(industryList);

    }).catch((error) => {
      console.error(error);

    });

  }

  async function findToolList() {
    await ToolController.findAll().then(result => {

      const tools = result.map((tool) => {
        return {
          ...tool, label: tool.toolName, value: tool.toolId,
        };
      });
      setTools(tools);

    }).catch((error) => {
      console.error(error);
    });

  }

  async function findClientBillingListByProject() {
    await ClientBillingController.findByProject(projectId_ENCRYPTED).then(result => {
      set_billingEntityDetails(result);
    }).catch((error) => {
      console.error(error);
    });
  }

  function renderTab(activeTab) {
    switch (activeTab) {
      case 1:
        if (userInfo.role.internalName === 'SALES') {
          return (<TabPane tabId={1}>
            {!_.isEmpty(project) &&
              <SalesProjectOverviewTab project={project} findProjectById={findProjectById}
                                       userInfo={userInfo} salesProjectAdmin={salesProjectAdmin} />}
          </TabPane>);
        } else {
          return (<TabPane tabId={1}>
            {!_.isEmpty(project) &&
              <PmMtProjectOverviewTab project={project} findProjectById={findProjectById}
                                      userInfo={userInfo} />}
          </TabPane>);
        }
        break;
      case 2:
        return (<TabPane tabId={2}>
          {!_.isEmpty(project) && <PmMtManagePartnerTab project={project}
                                                        userInfo={userInfo} />}
        </TabPane>);
        break;
      case 3:
        return (<TabPane tabId={3}>
          {!_.isEmpty(project) && <PmMtCostAnalysisTab project={project}
                                                       estimatedTotalProjectValue={estimatedTotalProjectValue}
                                                       totalProjectCost={totalProjectCost}
                                                       grossMargin={grossMargin}
                                                       findProjectById={findProjectById}
                                                       userInfo={userInfo}
                                                       salesProjectAdmin={salesProjectAdmin}
          />}
        </TabPane>);
        break;
      case 4:
        return (<TabPane tabId={4}>
          {!_.isEmpty(project) && <PmMtBillingViewTab project={project}
                                                      userInfo={userInfo}
                                                      totalProjectedRevenueCurrentYear={totalProjectedRevenueCurrentYear}
                                                      findProjectById={findProjectById}
                                                      salesProjectAdmin={salesProjectAdmin}
          />}
        </TabPane>);
        break;
      case 5:
        return (<TabPane tabId={5}>
          {!_.isEmpty(project) && <PmMtDocumentsNotesTab project={project} userInfo={userInfo}
                                                         findProjectById={findProjectById}
                                                         salesProjectAdmin={salesProjectAdmin}
          />}
        </TabPane>);
        break;
      case 6:
        return (<TabPane tabId={6}>
          {!_.isEmpty(project) && <PmMtPartnerProjectSummaryTab project={project} userInfo={userInfo}
                                                                findProjectById={findProjectById}
                                                                salesProjectAdmin={salesProjectAdmin}
          />}
        </TabPane>);
        break;

      default:
        break;
    }
  }

  function findProjectSowAndInvoicePendingActionByProjectId() {
    ProjectController.findByProjectId(projectId_ENCRYPTED).then(result => {
      set_projectSowInvoiceActionPendingData(result);
    }).catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    dispatch(ToggleMiniSidebar());
    set_isLoaded(false);


    async function fetchData() {
      if (userInfo.role.internalName === 'SALES') {
        await findProjectById();
        await findAllUserInfo();
        await findByProjectAndUserInfo();
        await findProjectSowAndInvoicePendingActionByProjectId();
        await findSolutionList();
        await findIndustryList();
        await findToolList();
        await findClientBillingListByProject();
        await findProjectStatusStage();
        set_isLoaded(true);
      } else {
        await findProjectById();
        await findAllUserInfo();
        await findProjectSowAndInvoicePendingActionByProjectId();
        await findSolutionList();
        await findIndustryList();
        await findToolList();
        await findClientBillingListByProject();
        await findProjectStatusStage();
        set_isLoaded(true);
      }
    }

    fetchData();

  }, []);
  const defaultValues = projectToolMappers.map((projectToolMapper) => {
    return {
      ...projectToolMapper.tool, label: projectToolMapper.tool.toolName, value: projectToolMapper.tool.toolId,
    };
  });

  /* const IdpEditTextInput = ({ id }) => {
       return (
           <div id={id}>

           </div>
       );
   }*/
  if (!isLoaded) {
    return (<div style={{ marginTop: '15%' }}><LoaderSpinner /></div>);
  } else {
    return (<div>
      <Row>

        <Col md={3} className="">
          <Card className={'border next_radious'}>
            <CardBody>
              <Row>
                <Col md={5}>
                  {!_.isEmpty(project) && <ProjectImageModal project={project} userInfo={userInfo} />}
                </Col>
                <Col md={7}>
                  <div className="mt-2 fw-semibold ">
                    {project.projectStatusStage?.stageInternalName === 'LOST' || project.projectStatusStage?.stageInternalName === 'COMPLETED' ?
                      (<div className={'integr8_project_name'} title={project.projectName}>{project.projectName}</div>)
                      :
                      (<div className={' d-flex align-items-end'}>
                        <div className={'integr8_project_name'} title={project.projectName}>{project.projectName}</div>
                        <div><i className="ti-marker-alt"
                                onClick={() => (projectNameChangeToggle())}
                                style={{ cursor: 'pointer' }}></i></div>
                      </div>)}

                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="d-flex mt-2">
                    <div>
                      <div>{t('program.manager.individual.project.header.client')}  </div>
                    </div>
                    <div>
                      {!_.isEmpty(project.projectClientMappers) &&

                        <div className="ms-1">
                          {(project.projectClientMappers.length > 1) ? (<div>
                              {project.projectClientMappers.map((projectClientMapper, pcmIndex) =>
                                <span
                                  key={pcmIndex}>{projectClientMapper.client.name},</span>)}
                            </div>

                          ) : (<div>
                            {project.projectClientMappers.map((projectClientMapper, pcmIndex) =>
                              <span
                                key={pcmIndex}>{projectClientMapper.client.name}</span>)}
                          </div>)}
                        </div>

                      }
                    </div>

                  </div>

                </Col>
              </Row>
              <Row>
                <div className={'d-flex flex-wrap'}>
                  <Col xs={12} md={6} className="mb-2">
                    <div className={'fw-bold mt-2'}
                         style={{ fontSize: '12px' }}
                         id="UncontrolledTooltipExampleFinalised"
                    >
                      {t('program.manager.individual.project.project.values')}</div>
                    <UncontrolledTooltip placement="top"
                                         target="UncontrolledTooltipExampleFinalised">

                      {t('pm.mt.individual.project.tooltip.value')}
                    </UncontrolledTooltip>
                    {project.projectStatus === 'N' ? (
                      <div className={'w-100'}>
                        {_.isEmpty(project.projectValue) ? 'N/A' : project.projectValue}
                      </div>
                    ) : (

                      <div className={'w-100'}>
                        <IdpEditTextInput
                          {...EditextProps}
                          type="text"
                          className="custom-input-height1"
                          inputProps={{
                            rows: 1,
                            placeholder: 'Type Here...',
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            onKeyPress: (event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            },
                          }}
                          value={new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(project.projectValue)}
                          onSave={(event) => {
                            const numericValue = event.replace(/[^0-9]/g, '');
                            onChangeProjectValue(numericValue);
                          }}
                        />
                      </div>
                    )}

                  </Col>
                  <Col xs={12} md={6} className="d-flex justify-content-md-end">
                    {project.projectStatusStage?.stageName !== 'In-Delivery' ? (
                      <div className="mt-2 w-100 w-md-auto">
                        <Select
                          options={_.isEmpty(project.projectStatusStage) ? statusStage : statusStage.filter(stage => stage.value !== project.projectStatusStage.pssId)}
                          onChange={projectStatusStageUpdateConfirm}
                          isDisabled={project.projectStatus === 'N' ? true : false}
                          classNamePrefix="next_select"
                          value={!_.isEmpty(project.projectStatusStage) ? {
                            label: project.projectStatusStage.stageName,
                            value: project.projectStatusStage.pssId,
                            color: statusStageColor.find(stage => stage.value === project.projectStatusStage.pssId)?.color,
                          } : null}

                          className="next_select"
                          placeholder={'Select Status'}
                          // styles={{container: (provided) => ({...provided, width: '100%'})}}
                          styles={customStyles}
                        />
                      </div>
                    ) : (
                      <div className="mt-2 w-100 w-md-auto">
                        <Select
                          options={tempStatusStage}
                          onChange={projectStatusStageUpdateConfirm}
                          isDisabled={project.projectStatus === 'N' ? true : false}
                          classNamePrefix="next_select"

                          value={!_.isEmpty(project.projectStatusStage) ? {
                            label: project.projectStatusStage.stageName,
                            value: project.projectStatusStage.pssId,
                            color: statusStageColor.find(stage => stage.value === project.projectStatusStage.pssId)?.color,
                          } : null}
                          className="next_select"
                          placeholder={'Select Status'}
                          styles={customStyles}
                        />
                      </div>)}

                  </Col>
                </div>
              </Row>
              <hr />
              <Row>
                <Col className={'pe-0'}>
                  <div className="mt-1"><span
                    className={'tw-font-bold'}>{t('program.manager.individual.project.code.body')}</span> {project.projectCode}
                  </div>
                </Col>

                <Col className="text-end ps-0">

                  {(userInfo.role.internalName === 'MT' || project.projectStatus === 'N') ? ('') : (
                    <i className="ti-marker-alt"
                       onClick={() => (editProjectDetail(project))}
                       style={{ cursor: 'pointer' }}></i>)}
                </Col>

              </Row>
              {/*<div className="mt-1">*/}
              {/*    <span*/}
              {/*        className="tw-font-bold">{t("program.manager.individual.project.industry")}</span>: {!_.isEmpty(project.industry) ? project.industry.industryName : ""}*/}
              {/*</div>*/}
              <div
                className="mt-1"><span
                className={'tw-font-bold'}>{t('program.manager.individual.project.no.of.participants')}</span> {!_.isEmpty(project) ? project.noOfParticipants : ''}
              </div>
              <Row className="mt-1">
                <Col md={12} className={'mb-1'}>
                                    <span
                                      className="tw-font-bold ">{t('program.manager.individual.project.start.row')}</span>: {!_.isNull(project.startDate) ? (
                  <Moment
                    name="startDate"
                    local
                    format="DD/MM/YYYY"
                    withTitle
                  >
                    {project.startDate}
                  </Moment>) : 'N/A'}
                </Col>
                <Col md={12}>
                                    <span
                                      className="tw-font-bold">{t('program.manager.individual.project.end.row')}</span>: {!_.isNull(project.endDate) ? (
                  <Moment
                    name="endDate"
                    format="DD/MM/YYYY"
                    local
                    withTitle
                  >
                    {project.endDate}
                  </Moment>) : 'N/A'}
                </Col>
              </Row>

              <div className="mt-1"><span
                className="tw-font-bold">{t('program.manager.individual.project.solution')}</span>: {!_.isEmpty(project.solution) ? project.solution.solution : ''}
              </div>

              <div
                className="mt-1"><span
                className={'tw-font-bold'}>{t('program.manager.individual.project.tools.used')}</span>
                <span>{projectToolMappers.map((projectToolMapper, ptmIndex) => <span
                  key={ptmIndex}> {projectToolMapper.tool.toolName}{projectToolMappers.length - 1 !== ptmIndex && (
                  <span>,</span>)}</span>)}</span>

              </div>


            </CardBody>

          </Card>


          <Card className={'border next_radious'}>
            <CardBody>
              <div>
                <div className={'d-flex'}>
                  <div className="mt-2"><span
                    className={'tw-font-bold'}>{t('program.manager.individual.project.sales.member.text')}</span>
                  </div>
                  {userInfo.role.internalName === 'MT' || project.projectStatus === 'N' ? (
                    <div className={'mt-2 ms-2'}
                         style={{ textAlign: 'center' }}>{_.isEmpty(project.userInfo) ? ('-') : (project.userInfo.name)}</div>
                  ) : (
                    <div className={'ms-4'} style={{ width: '53%' }}>
                      <Select
                        options={salesMembers}
                        // onChange={onChangeSalesMember}
                        onChange={onChangeSalesMemberConfirm}
                        isDisabled={userInfo.role.internalName === 'SALES' ? true : false}
                        defaultValue={!_.isEmpty(project.userInfo) ? {
                          label: project.userInfo.name,
                          value: project.userInfo.userId,
                        } : null}
                        classNamePrefix="next_select"
                        placeholder={'Select..'}
                        // styles={{container: (provided) => ({...provided, width: '100%'})}}
                      />
                    </div>)

                  }

                </div>
                <div className={'d-flex mt-2'}>
                  <div className="mt-2"><span
                    className={'tw-font-bold'}>{t('program.manager.individual.project.project.manager.text')}</span>
                  </div>
                  {userInfo.role.internalName === 'MT' || project.projectStatus === 'N' ? (
                    <div className={'mt-2 ms-2'}
                         style={{ textAlign: 'center' }}>{_.isEmpty(project.projectAdmin) ? ('-') : (project.projectAdmin.userInfo.name)}</div>
                  ) : (
                    <div className={'ms-2'} style={{ width: '53%' }}>
                      <Select
                        options={pmMembers}
                        // onChange={onChangePmMember}
                        onChange={onChangePmMemberConfirm}
                        value={!_.isEmpty(project.projectAdmin) ? {
                          label: project.projectAdmin.userInfo.name,
                          value: project.projectAdmin.userInfo.userId,
                        } : null}
                        isDisabled={userInfo.role.internalName === 'PM' ? true : false}
                        classNamePrefix="next_select"
                        placeholder={'Select..'}
                        styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
                      />
                    </div>)}
                </div>

              </div>
            </CardBody>
          </Card>


          <Card className={'border next_radious'}>
            <span className="lstick bg-info"></span>
            <CardBody>
              <ClientContactView project={project} findProjectById={findProjectById} />
            </CardBody>
          </Card>

          <Card className={'border next_radious'}>
            <span className="lstick bg-info"></span>
            <CardBody>
              <BillingEntityView billingEntityDetails={billingEntityDetails} project={project}
                                 findProjectById={findProjectById}
                                 findClientBillingListByProject={findClientBillingListByProject} />
            </CardBody>
          </Card>


        </Col>

        <Col md={9} className="">
          <Card className={'border next_radious'}>
            <CardBody>
              {project.projectStatusStage?.stageName === 'In-Delivery' || project.projectStatusStage?.stageName === 'Completed' ? (
                <div className="next_tabs next_client_tabs modal_containt_e2e">

                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href={'#1'}
                        className={classNames({ active: activeTab === 1 })}
                        onClick={() => {
                          handleChangeTab(1);
                        }}
                      >{t('pm.individual.project.project.overview.text')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#2'}
                        className={classNames({ active: activeTab === 2 })}
                        onClick={() => {
                          handleChangeTab(2);
                        }}
                      >{t('program.manager.individual.project.partner.manage')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#3'}
                        className={classNames({ active: activeTab === 3 })}
                        onClick={() => {
                          handleChangeTab(3);
                        }}
                      >{t('program.manager.individual.project.cost.analysis')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#4'}
                        className={classNames({ active: activeTab === 4 })}
                        onClick={() => {
                          handleChangeTab(4);
                        }}
                      >{t('program.manager.individual.project.billing')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#5'}
                        className={classNames({ active: activeTab === 5 })}
                        onClick={() => {
                          handleChangeTab(5);
                        }}
                      >{t('program.manager.individual.project.documents')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#6'}
                        className={classNames({ active: activeTab === 6 })}
                        onClick={() => {
                          handleChangeTab(6);
                        }}
                      >{t('pm.individual.project.project.summary.text')}

                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>) : (
                <div className="next_tabs next_client_tabs modal_containt_e2e">

                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href={'#1'}
                        className={classNames({ active: activeTab === 1 })}
                        onClick={() => {
                          handleChangeTab(1);
                        }}
                      >{t('pm.individual.project.project.overview.text')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#3'}
                        className={classNames({ active: activeTab === 3 })}
                        onClick={() => {
                          handleChangeTab(3);
                        }}
                      >{t('program.manager.individual.project.cost.analysis')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#4'}
                        className={classNames({ active: activeTab === 4 })}
                        onClick={() => {
                          handleChangeTab(4);
                        }}
                      >{t('program.manager.individual.project.billing')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#5'}
                        className={classNames({ active: activeTab === 5 })}
                        onClick={() => {
                          handleChangeTab(5);
                        }}
                      >{t('program.manager.individual.project.documents')}

                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={'#6'}
                        className={classNames({ active: activeTab === 6 })}
                        onClick={() => {
                          handleChangeTab(6);
                        }}
                      >{t('pm.individual.project.project.summary.text')}

                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              )}
              <TabContent activeTab={activeTab}>
                {renderTab(activeTab)}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>


      {/* ======================================    Close Project modal ===================================== */}

      <Modal
        isOpen={closeProjectModal}
        toggle={toggleCloseProjectModal}
      >
        <ModalHeader
          toggle={toggleCloseProjectModal}>
          {selectValue.stageName === 'Completed' ? t('program.manager.individual.project.modal.header.project.completed') : t('program.manager.individual.project.modal.header.project.lost')
          }
        </ModalHeader>
        {(projectSowInvoiceActionPendingData.totalSowActionPendingCount === 0 && projectSowInvoiceActionPendingData.totalSowInvoiceActionPendingCount === 0) ? (
          <ModalBody>
            <div>
              <b>{selectValue.stageName === 'Completed' ? t('program.manager.individual.project.modal.header.close.this.project') : t('program.manager.individual.project.modal.header.lost.this.project')}</b>
            </div>
            {selectValue.stageName === 'Completed' ? (

              <ul className="mt-2">  {t('program.manager.individual.project.once.project')}
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.markas.completed')}</li>
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.cannot.open')}</li>
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.cannot.edit')}</li>
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.partner.associated')}</li>
                <li
                  className="mt-1">{t('program.manager.individual.project.modal.body.partner.raise')}</li>
              </ul>
            ) : (
              <ul className="mt-2">  {t('program.manager.individual.project.once.project.lost')}
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.closed.cant.open')}</li>
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.within.the.project')}</li>
              </ul>)}
          </ModalBody>) : (<ModalBody>
          <div><b>{t('pm.close.project.modal.header.text')}</b></div>
          <ul className="mt-2">
            <li
              className="mt-1"> {t('pm.close.project.modal.body.text1')}: <span>{projectSowInvoiceActionPendingData.totalSowActionPendingCount}</span>
            </li>
            <li
              className="mt-1"> {t('pm.close.project.modal.body.text2')}: <span>{projectSowInvoiceActionPendingData.totalSowInvoiceActionPendingCount}</span>
            </li>
          </ul>
        </ModalBody>)}

        {
          (projectSowInvoiceActionPendingData.totalSowActionPendingCount === 0 && projectSowInvoiceActionPendingData.totalSowInvoiceActionPendingCount === 0) ? (
            <ModalFooter>
              <Button
                className="next_cancel_btn"
                onClick={toggleCloseProjectModal}>
                {t('integr8.common.cancel.text')}
              </Button>
              <ButtonWithLoader
                className="next_accent2_btn ms-2"
                loading={isConfirmBtnLoading}
                onClick={selectValue.stageName === 'Completed' ? closeProject : lostProject}
                text={selectValue.stageName === 'Completed' ? t('program.manager.individual.project.modal.footer.btn.closed.project') : t('program.manager.individual.project.modal.footer.btn.lost.project')}
              >
              </ButtonWithLoader>
            </ModalFooter>) : (<ModalFooter>
            <Button
              className="next_cancel_btn"
              onClick={toggleCloseProjectModal}>
              {t('integr8.close.btn.text')}
            </Button>
          </ModalFooter>)}
      </Modal>


      {/* ======================================      Update Project Details modal ===================================== */}

      <Modal
        isOpen={projectDetailsModal}
        toggle={cancelProjectDetailsModal}
      >
        <ModalHeader
          toggle={cancelProjectDetailsModal}>{t('pm.individual.project.update.details')}</ModalHeader>
        <ModalBody>
          {/*<div>
                        <Row>
                            <Col md={3}><label
                                className="mt-1">{t("program.manager.individual.project.industry")}</label></Col>
                            <Col md={9}>
                                <Select
                                    defaultValue={!_.isEmpty(project.industry) ? {
                                        label: project.industry.industryName, value: project.industry.industryName
                                    } : null}
                                    classNamePrefix="next_select"
                                    options={industries}
                                    onChange={onProjectIndustryChange}
                                    placeholder={t("integr8.select.placeholder.text")}/>
                            </Col>

                        </Row>

                    </div>*/}
          <div className="mt-2">
            <Row>
              <Col md={3}><label
                className="mt-1">{t('program.manager.individual.project.solution')}</label></Col>
              <Col md={9}>
                <Select
                  defaultValue={!_.isEmpty(project.solution) ? {
                    label: project.solution.solution, value: project.solution.solution,
                  } : null}
                  classNamePrefix="next_select"
                  options={solutions}
                  onChange={onProjectSolutionChange}
                  placeholder={t('integr8.select.placeholder.text')} />
              </Col>

            </Row>
          </div>
          <div className="mt-2">
            <Row>
              <Col md={3}><label
                className="mt-1">{t('program.manager.individual.project.used.tools')}</label></Col>
              <Col md={9}>
                <Select
                  classNamePrefix="next_select"
                  defaultValue={defaultValues.length > 0 ? defaultValues : []}
                  isMulti
                  options={tools}
                  onChange={onProjectToolChange}
                  placeholder={t('integr8.select.placeholder.text')} />
              </Col>

            </Row>
          </div>

          <div className="mt-2">
            <Row>
              <Col md={3}><label
                className="mt-1">{t('program.manager.individual.project.start.date')}</label></Col>
              <Col md={9}>
                <div className="target_date_container">
                  <Input className="input-text-next mb-1 target_date"
                         type="date"
                         name="targetDate"
                         id="targetDate"
                         placeholder="dd-mm-yyyy"
                         value={projectUpdate.startDate}
                         onChange={onStartDateChange}
                    // min={disablePastDate()}
                         max="2050-12-31"
                  >
                  </Input>
                </div>
              </Col>

            </Row>
          </div>

          <div className="mt-2">
            <Row>
              <Col md={3}><label
                className="mt-1">{t('program.manager.individual.project.end.date')}</label></Col>
              <Col md={9}>
                <div className="target_date_container">
                  <Input className="input-text-next mb-1 target_date"
                         type="date"
                         name="targetDate"
                         id="targetDate"
                         placeholder="dd-mm-yyyy"
                         value={projectUpdate.endDate}
                         onChange={onEndDateChange}
                         min={disablePastDate()}
                         max="2050-12-31"
                  >
                  </Input>
                </div>
              </Col>

            </Row>
          </div>
          <div className="mt-2">
            <Row>
              <Col md={3}><label>{t('program.manager.individual.project.no.participants')}</label></Col>
              <Col md={9}>
                <Input type="number" min={1}
                       name="noOfParticipants"
                       onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                       className="next_input"
                       onChange={onProjectDetailsChange}
                       value={projectUpdate.noOfParticipants}
                       placeholder={t('common.component.proj.clnt.cont.type.here')} />
              </Col>

            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="next_cancel_btn "
                  onClick={cancelProjectDetailsModal}>
            {t('pm.project.cancel.btn')}
          </Button>

          <ButtonWithLoader
            className=" next_btn ms-2"
            loading={isUpdateBtnLoading}
            disabled={validateDisable()}
            onClick={updateProjectDetails}
            text={t('program.manager.individual.project.update.btn')}
          >
          </ButtonWithLoader>

        </ModalFooter>

      </Modal>


      {/*=======================================================Status Stage Confirm Modal=======================================================*/}


      <Modal
        isOpen={projectModal}
        toggle={projectModalToggle}
        backdrop="true"
      >
        <ModalHeader
          toggle={projectModalToggle} className="tw-text-base"
        >{selectValue.stageName === 'In-Delivery' ? t('integr8.pm.mt.individual.header.in.delivery') : t('integr8.pm.mt.individual.header.change.deal')}
        </ModalHeader>
        <ModalBody>
          <div className="tw-text-sm">
            <div className="fw-bold text-center">
              <b>{selectValue.stageName === 'In-Delivery' ? t('integr8.pm.mt.individual.change.status.in.delivery') : t('integr8.pm.mt.individual.change.status')}</b>
            </div>
            {selectValue.stageName === 'In-Delivery' ?
              (<ul className="mt-2 ms-3 me-4">
                <li
                  className="mt-1"> {t('program.manager.individual.project.modal.body.in.delivery.once')}</li>
              </ul>)
              :
              (<div>
                {!_.isEmpty(project.projectStatusStage) ? (
                  <Row>
                    <div className="text-center mt-2">
                      {t('integr8.pm.mt.individual.change.status.modal.form')}</div>
                    <div
                      className="fw-bold text-center mt-2">  {project.projectStatusStage.stageName} {' '} to {selectValue.label} </div>
                  </Row>
                ) : (
                  <Row>
                    <div className="text-center mt-2">
                      {t('integr8.pm.mt.individual.change.status.modal.to')}</div>
                    <div className="fw-bold text-center mt-2">{selectValue.label}</div>
                  </Row>
                )}
              </div>)}
          </div>
        </ModalBody>
        <ModalFooter>
          {selectValue.stageName === 'In-Delivery' ? (
            <div className="text-end">
              <Button className="next_cancel_btn "
                      onClick={projectModalToggle}>
                {t('integr8.common.cancel.text')}
              </Button>
              <ButtonWithLoader
                className="next_btn_success next_btn_lg ms-2"
                loading={isStatusUpdateLoading}
                onClick={projectStatusStageUpdate}
                text={t('integr8.pm.mt.individual.project.modal.cnfrm.btn.in.delivery')}
              >
              </ButtonWithLoader>

            </div>) : (
            <div className="text-end">
              <Button className="next_cancel_btn "
                      onClick={projectModalToggle}>
                {t('integr8.common.cancel.text')}
              </Button>
              <ButtonWithLoader
                className="next_accent2_btn next_btn_lg ms-2"
                loading={isStatusUpdateLoading}
                onClick={projectStatusStageUpdate}
                text={t('integr8.pm.mt.individual.project.modal.cnfrm.btn')}
              >
              </ButtonWithLoader>

            </div>
          )}
        < /ModalFooter>

      </Modal>


      {/*====================================================PM Member Confirm Modal=======================================================*/}

      <Modal
        isOpen={projectPMMember}
        toggle={pmMemberToggle}
        backdrop="true"
      >
        <ModalHeader
          toggle={pmMemberToggle} className="tw-text-base">
          {t('pm.sales.projects.member.update.pm.confirm.modal.header')}
        </ModalHeader>
        <ModalBody>
          <div className="tw-text-sm">
            <div className="fw-bold text-center mb-4">
              <b>{t('pm.sales.projects.member.update.pm.confirm.modal.are.you.sure')}</b>
            </div>

            <Row>
              <div className="d-flex">
                <div className="fw-bold ms-2">
                  {t('pm.sales.projects.member.update.confirm.modal.project.text')}</div>

                <div className="ms-2"> {project.projectName} </div>
              </div>
            </Row>
            <Row>
              <div className="d-flex">
                <div className={'fw-bold ms-2'}>
                  {t('pm.sales.projects.member.update.confirm.modal.current.pm.text')}</div>

                <div
                  className="ms-2"> {_.isEmpty(project.projectAdmin) ? '-' : project.projectAdmin.userInfo.name}  </div>
              </div>
            </Row>
            <Row>
              <div className="d-flex">
                <div className={'fw-bold ms-2'}>
                  {t('pm.sales.projects.member.update.confirm.modal.change.to.text')}</div>

                <div className="ms-2">{selectPmValue.label}</div>
              </div>
            </Row>


          </div>
        </ModalBody>
        <ModalFooter>
          <div className="text-end">
            <Button className="next_cancel_btn "
                    onClick={pmMemberToggle}>
              {t('integr8.common.cancel.text')}
            </Button>

            <ButtonWithLoader
              className="next_accent2_btn next_btn_lg ms-2"
              loading={isStatusUpdateLoading}
              onClick={pmMemberChange}
              text={t('integr8.pm.mt.individual.project.modal.cnfrm.btn')}
            >
            </ButtonWithLoader>

          </div>
        </ModalFooter>

      </Modal>

      {/*====================================================Sales Member Confirm Modal=======================================================*/}

      <Modal
        isOpen={projectSalesMember}
        toggle={salesMemberToggle}
        backdrop="true"
      >
        <ModalHeader
          toggle={salesMemberToggle} className="tw-text-base">
          {t('pm.sales.projects.member.update.sales.confirm.modal.header')}
        </ModalHeader>
        <ModalBody>
          <div className="tw-text-sm">
            <div className="fw-bold text-center mb-4">
              <b>{t('pm.sales.projects.member.update.sales.confirm.modal.are.you.sure')}</b></div>

            <Row>
              <div className="d-flex">
                <div className="fw-bold  ms-2">
                  {t('pm.sales.projects.member.update.confirm.modal.project.text')}</div>
                <div className="ms-2"> {project.projectName} </div>
              </div>
            </Row>

            <Row>
              <div className="d-flex">
                <div className="fw-bold mt-2 ms-2">
                  {t('pm.sales.projects.member.update.confirm.modal.current.sales.text')}</div>

                <div
                  className="ms-2 mt-2"> {_.isEmpty(project.userInfo) ? '-' : project.userInfo.name}</div>
              </div>
            </Row>

            <Row>
              <div className="d-flex">
                <div className="fw-bold mt-2 ms-2">
                  {t('pm.sales.projects.member.update.confirm.modal.change.to.text')}</div>

                <div className=" ms-2 mt-2">{selectSalesValue.label}</div>
              </div>
            </Row>


          </div>
        </ModalBody>
        <ModalFooter>
          <div className="text-end">
            <Button className="next_cancel_btn "
                    onClick={salesMemberToggle}>
              {t('integr8.common.cancel.text')}
            </Button>

            <ButtonWithLoader
              className="next_accent2_btn next_btn_lg ms-2"
              loading={isStatusUpdateLoading}
              onClick={salesMemberChange}
              text={t('integr8.pm.mt.individual.project.modal.cnfrm.btn')}
            >
            </ButtonWithLoader>

          </div>
        </ModalFooter>

      </Modal>

      {/*=====================================================Project Name Change Modal=======================================================*/}

      <Modal
        isOpen={projectNameChangeModal}
        toggle={projectNameChangeToggle}
        backdrop="true"
      >
        <ModalHeader
          toggle={projectNameChangeToggle} className="tw-text-base">
          {t('pm.project.name.change.modal.heading')}
        </ModalHeader>
        <ModalBody>
          <div>
            <Input type="textarea" className="next_input" name="projectName"
                   bsSize="sm"
                   autoFocus={true}
                   style={{ height: '100px', fontSize: '14px' }}
                   defaultValue={project.projectName}
                   onChange={(event) => onProjectNameChange(event)}
                   placeholder={t(`common.component.proj.clnt.cont.type.here`)} />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="text-end">
            <Button className="next_cancel_btn "
                    onClick={projectNameChangeToggle}>
              {t('integr8.common.cancel.text')}
            </Button>

            <ButtonWithLoader
              className="next_btn next_btn_lg ms-2"
              loading={isProjectNameUpdateLoading}
              onClick={onProjectNameUpdate}
              disabled={_.isEmpty(projectNameUpdate.projectName)}
              text={t('integr8.common.update.text')}
            >
            </ButtonWithLoader>

          </div>
        </ModalFooter>

      </Modal>


    </div>);
  }

}


export default PmMtIndividualProjectView;