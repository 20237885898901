import React, { useMemo, useState } from 'react';
import { Badge, Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DataTableView from '../../../components/data-table/data-table-view';
import Moment from 'react-moment';
import moment from 'moment';
import conf_prop from '../../../properties/properties';
import ProjectInvoiceController from '../../../api/controller/projectinvoice-controller';
import MonthYearPicker from 'react-month-year-picker';
import UploadFile from '../../../components/upload-file';
import _ from 'lodash';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';


function PmMtFinanceProjectInvoice({
                                       findProjectInvoiceByProject,
                                       projectInvoices,
                                       userInfo,
                                       project,
                                       salesProjectAdmin,
                                   }) {

    const {t} = useTranslation();
    const [togglePendingStatus, set_togglePendingStatus] = useState(false);
    const [isBtnLoading, set_isBtnLoading] = useState(false);
    const [editInvoice, set_toggleEditInvoice] = useState(false);
    const currYear = new Date().getFullYear();
    const [files, set_files] = useState([]);
    const [errorMessage, set_errorMessage] = useState(null);
    const [projectInvoice, set_projectInvoice] = useState({
        "piId": null, "project": {"projectId": null}, "projectAdmin": {"paId": null}
    });
    const [projectInvoiceUpdate, set_projectInvoiceUpdate] = useState({
        piId: '',
        invoiceAmount: '',
        invoiceYear: currYear,
        invoiceMonth: new Date().getMonth() + 1,
        // project: {projectId: project.projectId},
        // projectAdmin: {paId: project.projectAdmin.paId},
    });


    function editInvoiceModal(rowData) {
        //console.log('rowData', rowData)

        set_projectInvoiceUpdate({
            ...projectInvoiceUpdate,
            piId: rowData.piId,
            invoiceAmount: rowData.invoiceAmount,
            invoiceDocName: rowData.invoiceDocName,
            invoiceYear: rowData.invoiceYear,
            invoiceMonth: rowData.invoiceMonth,
            project: {projectId: project.projectId},
            projectAdmin: {paId: project.projectAdmin.paId}
        });
        set_files([]);
        toggleEditInvoiceModal();
    }

    function onProjectInvoiceChange(e) {
        const {name, value} = e.target;
        if (value === '0') {
            set_errorMessage(true);

        } else if (/^0\d/.test(value)) {
            set_errorMessage(false);
            set_projectInvoiceUpdate(prevState => {
                return { ...prevState, [name]: value.replace(/^0+/, '') };
            });

        } else {
            set_errorMessage(false);
            set_projectInvoiceUpdate(prevState => {
                return { ...prevState, [name]: value };
            });
        }
    }

    function handleFileState(files) {
        set_files(files);
    }

    function togglePendingStatusModal() {
        set_togglePendingStatus(togglePendingStatus_prev => !togglePendingStatus_prev);
    }

    function toggleEditInvoiceModal(rowData) {
        set_toggleEditInvoice(toggleEditInvoice_prev => !toggleEditInvoice_prev);
        set_errorMessage(null);
    }

    function onClickPaidBtn(rowData) {
        set_projectInvoice(projectInvoice_prev => {
            return {
                ...projectInvoice_prev,
                piId: rowData.piId,
                project: {projectId: project.projectId},
                projectAdmin: {paId: project.projectAdmin.paId}
            };
        })
        togglePendingStatusModal();
    }

    async function updateProjectInvoice() {
        const updatedProjectInvoiceData = {
            ...projectInvoiceUpdate,
            project: {projectId: project.projectId},
            projectAdmin: {paId: project.projectAdmin.paId}
        };
        if (!_.isEmpty(files)) {
            set_isBtnLoading(true);
            const formData = new FormData();
            formData.append("projectInvoiceJson", JSON.stringify(updatedProjectInvoiceData));

            files.forEach(file => {
                formData.append("uploadedFile", file);
            });
            ProjectInvoiceController.updateProjectInvoiceWithFile(formData).then(result => {
                toastify('success', t('common.component.project.invoice.updated'), t('common.component.project.invoice.updated'));
                findProjectInvoiceByProject();
                toggleEditInvoiceModal();
                set_isBtnLoading(false);
            }).catch(error => {
                console.error('Error:', error);
                toastify('error', t('common.component.project.invoice.updated.error'), t('common.component.project.invoice.updated.error'));
            });
        } else {
            set_isBtnLoading(true);
            ProjectInvoiceController.updateProjectInvoice(updatedProjectInvoiceData).then(result => {
                toastify('success', t('common.component.project.invoice.updated'), t('common.component.project.invoice.updated'));
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                findProjectInvoiceByProject();
                toggleEditInvoiceModal();
                set_isBtnLoading(false);
            });
        }
    }

    const columns = useMemo(() => [{
            name: t('pm.project.invoice.column.year'),
            sortable: true,
            selector: rowData => rowData.invoiceYear,
            filter: (rowData, value) => rowData.invoiceYear.toString().toLowerCase().includes(value.toLowerCase()),
        },

            {
                name: t('pm.project.invoice.column.Month'),
                sortable: true,
                filter: (rowData, value) => moment().month(rowData.invoiceMonth - 1).format('MMMM').toLowerCase().includes(value.toLowerCase()),
                cell: (rowData) => <Moment
                    format="MMMM">{moment().set('month', rowData.invoiceMonth - 1)}</Moment>,
            }, {
                name: t('pm.project.invoice.column.invoiced.amount'),
                sortable: true,
                selector: rowData => rowData.invoiceAmount.toLocaleString('en-IN'),
                filter: (rowData, value) => rowData.invoiceAmount.toString().toLowerCase().includes(value.toLowerCase()),
            }, {
                name: t('pm.project.invoice.column.invoice'), sortable: true, cell: (rowData) => <a
                    href={conf_prop.get('integr8ServiceUrl') + '/rest/projectinvoice/invoiceDoc-download/' + rowData.piId_ENCRYPTED}><i
                    className="ti-download" style={{color: '#37A4FE'}}></i></a>,
            }, {
                name: t('pm.project.invoice.payment.status'),
                // sortable: true,
                // selector: rowData => rowData.paymentStatus
                cell: (rowData) => <span>
                {rowData.paymentStatus === 'Y' ?
                    (<div className="text-center">
                        <Badge
                            className="integr8_next_fluid_badge_success ms-2">
                            {t('common.component.project.invoice.btn.paid')}</Badge>
                        <br/>
                        <small className="text-center font-10">
                            <Moment format="DD/MM/YYYY">{rowData.paymentDate}</Moment>
                        </small>
                    </div>) : (<div className="text-center">
                        {userInfo.role.internalName === 'FINANCE' ?
                            (<span>
                                <Badge className="integr8_next_fluid_badge_warning" onClick={() => {
                                    onClickPaidBtn(rowData);
                                }}
                                       style={{cursor: 'pointer'}}
                                >{t('common.component.project.invoice.btn.pending')}</Badge>
                            </span>) : (<span><Badge
                                className="integr8_next_fluid_badge_warning">{t('common.component.project.invoice.btn.pending')}
                            </Badge></span>)}
                    </div>)}
            </span>
            },
            {
                name: "",
                cell: (rowData) => {
                    if (project.projectStatus === 'N') {
                        return <span></span>;
                    } else {
                        return (
                            <span>
                <div className="">
                    {userInfo.role.internalName === 'FINANCE' && (
                        <div>
                            {rowData.paymentStatus === 'Y' ? (
                                <strong className=""></strong>
                            ) : (
                                <Button className="next_outline_accent1_btn"
                                        onClick={() => editInvoiceModal(rowData)}> Edit</Button>
                            )}
                        </div>
                    )}
                </div>
            </span>
                        );
                    }
                }


            }
        ]
    );


    function updatePaymentStatus() {
        set_isBtnLoading(true);
        ProjectInvoiceController.updateProjectInvoicePaymentStatus(projectInvoice).then(result => {
            toastify('success', t('common.component.project.invoice.payment.status.updated'), t('common.component.project.invoice.payment.status.updated'));
            findProjectInvoiceByProject();
            togglePendingStatusModal();
            set_isBtnLoading(false);
        }).catch(error => {
            console.error('Error:', error);
            toastify('error', t('common.component.project.invoice.status.payment.updated.error'), t('common.component.project.invoice.status.payment.updated.error'));
        });
    }

    return (<div>

        <div className="feedback_table containt_e2e-datatable mt-3">
            <DataTableView
                title={t('pm.close.projects.title')}
                columns={columns}
                data={_.orderBy(projectInvoices, ['genDate'], ['desc'])}
                options={{search: true, extendedSearch: false, pagination: true}}
            />
        </div>

        {/*=============================================Project Invoice Paid Modal===========================================*/}

        <Modal
            isOpen={togglePendingStatus}
            toggle={togglePendingStatusModal}
            // backdrop={false}
            backdrop={'static'}
        >
            <ModalHeader
                toggle={togglePendingStatusModal}>{t('pm.project.head.change.status')}</ModalHeader>
            <ModalBody>


            </ModalBody>
            <div className="font-14 text-center fw-bold">
                {t('pm.project.sub.head.payment.status.paid')}</div>
            <ModalFooter>
                <div>
                    <Button className="next_cancel_btn" onClick={togglePendingStatusModal}>
                        {t('integr8.common.close.text')}
                    </Button>

                    <ButtonWithLoader
                        className="next_btn_success_integr8 ms-2"
                        loading={isBtnLoading}
                        onClick={updatePaymentStatus}
                        text={t('integr8.project.invoice.btn.mark.paid')}
                    >
                    </ButtonWithLoader>
                </div>
            </ModalFooter>
        </Modal>
        {/*================================================ Edit Invoice Modal =========================================*/}
        <Modal
            isOpen={editInvoice}
            toggle={toggleEditInvoiceModal}
            backdrop="static"
        >
            <ModalHeader
                toggle={toggleEditInvoiceModal}>{t('finance.billing.edit.modal.edit.invoice')}</ModalHeader>
            <ModalBody>
                <div>
                    <FormGroup className={'integr8_month-year-picker'}>
                        <MonthYearPicker
                            selectedMonth={projectInvoiceUpdate.invoiceMonth}
                            selectedYear={projectInvoiceUpdate.invoiceYear}
                            minYear={2020}
                            maxYear={2030}
                            onChangeYear={year => {
                                set_projectInvoiceUpdate(projectInvoice_prev => {
                                    return {...projectInvoice_prev, invoiceYear: year}
                                })
                            }}
                            onChangeMonth={month => {
                                set_projectInvoiceUpdate(projectInvoice_prev => {
                                    return {...projectInvoice_prev, invoiceMonth: month}
                                })
                            }}

                        />
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={3}><label
                                className="mt-2">{t("finance.billing.edit.modal.project.actual.rev.amount")}</label></Col>
                            <Col md={9}>
                                <Input type="number" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                       name="invoiceAmount"
                                       min={1}
                                       value={projectInvoiceUpdate.invoiceAmount}
                                       onChange={onProjectInvoiceChange}
                                       placeholder={t("pm.new.projection.modal.placeholder.text")}
                                       className="mb-2 next_input"/>
                                {errorMessage ? (
                                  <div
                                    className="text-danger font-10">{t('integr8.projection.modal.error.msg')}</div>) : null}
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <div className="">{t("finance.billing.edit.modal.attach.invoice")}</div>
                        <div className="d-flex mt-2" style={{color: '#37A3FE'}}>
                            <span className="tw-text-xs me-1">Attached File:</span>
                            <span className="tw-text-xs">{projectInvoiceUpdate.invoiceDocName}</span>
                        </div>
                        <small className="font-10">{t("finance.billing.edit.modal.attach.invoice.reupload")}</small>
                        <div className="mt-2">
                            <UploadFile
                                files={files}
                                handleFileState={handleFileState}
                            />
                        </div>

                    </FormGroup>
                </div>

            </ModalBody>

            <ModalFooter>
                <div>
                    <Button className="next_cancel_btn" onClick={toggleEditInvoiceModal}>
                        {t('integr8.common.close.text')}
                    </Button>

                    <ButtonWithLoader
                        className=" next_btn ms-2"
                        loading={isBtnLoading}
                        onClick={updateProjectInvoice}
                        disabled={_.isEmpty(projectInvoiceUpdate.invoiceAmount) || (projectInvoiceUpdate.invoiceAmount).length === 0 || errorMessage === true}
                        text={t('finance.billing.inst.btn.save')}
                    >
                    </ButtonWithLoader>
                </div>
            </ModalFooter>
        </Modal>
    </div>);
}

export default PmMtFinanceProjectInvoice;